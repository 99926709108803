import { ErrorObject } from 'serialize-error';
import { STATUS } from '../../../../store/api/constants';
import { AGENT_SEARCH_ACTIONS, searchActions } from './actions';

export const initialState = {
    loading: false,
    agents: [],
    error: null as ErrorObject | null,
};

export type AgentSearch = typeof initialState;

export default function (state = initialState, action: searchActions) {
    switch (action.type) {
        case AGENT_SEARCH_ACTIONS.search:
            switch (action.status) {
                case STATUS.Succeeded:
                    return { ...state, agents: action.agents, loading: false };
                case STATUS.Failed:
                    return { ...state, error: action.error, loading: false };
                case STATUS.Requested:
                    return { ...state, loading: true };
                default:
                    return state;
            }
        default:
            return state;
    }
}
