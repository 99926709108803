import { Listing, Showing } from './constants';
import _ from 'lodash';
import { isEmpty } from 'ramda';

/**
 * This function will take an array arranged in the order monday - sunday,
 * and rearrange the order to match the current day of the week.
 * @param {unavailableDates} array of 7 days
 */
export const arrangeDates = (unavailableDates: any[]) => {
    const date = new Date();
    const day = date.getDay();

    // first day of unavailableDates week (monday)
    var dayKey = 1;
    var week = [...unavailableDates];

    // rotate week until it is aligned with today being the first day
    while (dayKey !== day) {
        const firstElem = week[0];
        week.shift();
        week.push(firstElem);
        dayKey++;
        if (dayKey > 6) {
            dayKey = 0;
        }
    }
    return week;
};

export const formatAvailability = (listing: any, showings: any) => {
    console.log('format');
    var unavailableDates: any = [];
    if (listing) {
        console.log('listing');
        var lastDate: any = new Date();
        lastDate.setHours(0, 0, 0, 0);
        lastDate.setDate(lastDate.getDate() + 7);

        const listingAvailability = listing?.agentListing?.availability?.recurring;
        const noticeRequired = listing?.agentListing?.noticeRequired;

        var now = new Date();
        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        var finalHour = now.getHours() + noticeRequired / 60;
        var createTomorrowBlock = false;
        if (finalHour > 24) {
            createTomorrowBlock = true;
            finalHour -= 24;
        }

        var startHours = now.getHours();
        var startMinutes = Math.floor(now.getMinutes() / 15) * 15;
        var endHours =
            now.getHours() + noticeRequired / 60 < 22 ? now.getHours() + noticeRequired / 60 : 22;
        var endMinutes = now.getMinutes() + noticeRequired / 60;
        if (endMinutes > 60) {
            endHours++;
            endMinutes -= 60;
        }
        endMinutes = Math.ceil(now.getMinutes() / 15) * 15;

        var noticeBlock1 = noticeRequired
            ? {
                  start: {
                      hours: startHours,
                      minutes: startMinutes,
                  },
                  end: {
                      hours: endHours,
                      minutes: endMinutes,
                  },
                  weekday: now.getDay(),
                  overlap: false,
              }
            : null;

        var tomorrowBlock = createTomorrowBlock
            ? {
                  start: {
                      hours: 5,
                      minutes: 0,
                  },
                  end: {
                      hours: finalHour,
                      minutes: endMinutes,
                  },
                  weekday: tomorrow.getDay(),
                  overlap: false,
              }
            : null;
        var upTillNowBlock = {
            start: {
                hours: 5,
                minutes: 0,
            },
            end: {
                hours: startHours,
                minutes: startMinutes,
            },
            weekday: now.getDay(),
            overlap: false,
        };
        console.log('upTillNow', upTillNowBlock);
        // unavailableDates.[0]push(upTillNowBlock);

        /**
         * Shift the date because listing availability date time starts
         * on monday, not on sunday
         */

        var shiftedNowIndex = now.getDay() - 1;
        var shiftedTomorrowIndex = now.getDay();
        if (shiftedNowIndex === -1) {
            shiftedNowIndex = 6;
        }
        if (shiftedTomorrowIndex === -1) {
            shiftedTomorrowIndex = 6;
        }
        console.log('lisAval', listingAvailability);

        // check if agentListing has changed listing availability
        if (listingAvailability && !isEmpty(listingAvailability)) {
            console.log('listingAvailability');
            /**
             * Get the current day being looked at. Assuming listingAvailability
             * exists, this means that the listing is connected, thus the start
             * time is 7am and end time is 9pm.
             */
            var dayStart: any = new Date();
            var dayEnd: any = new Date();
            dayStart.setHours(5, 0, 0, 0);
            dayEnd.setHours(21, 0, 0, 0);

            const days = Object.keys(listingAvailability);

            days.map((dayKey: any, index: number) => {
                const day = listingAvailability[dayKey];

                // get the current day being looked at
                var currentDayStart = dayStart;
                currentDayStart.setDate(currentDayStart.getDate() + index);

                var currentDayEnd = dayEnd;
                currentDayEnd.setDate(currentDayEnd.getDate() + index);

                /**
                 * if startTime for a day is not an empty string, then the
                 * agent has restricted part of that day, so there will be
                 * one or two unavailability blocks
                 */
                if (day && day.startTime !== '') {
                    var dayAvailability = [];
                    var startBlock = null;
                    var endBlock = null;
                    /**
                     * If the available start time comes after the day opens,
                     * then there is a block of unavailability at the
                     * beginning of the day
                     */
                    if (day.startTime.getHours() !== currentDayStart.getHours()) {
                        startBlock = {
                            start: {
                                hours: currentDayStart.getHours(),
                                minutes: currentDayStart.getMinutes(),
                            },
                            end: {
                                hours: day.startTime.getHours(),
                                minutes: day.startTime.getMinutes(),
                            },
                            // overlap always false for listing constraints
                            overlap: false,
                        };
                        dayAvailability.push(startBlock);
                    }
                    /**
                     * If the available end time comes before the day end,
                     * then there is a block of unavailability at the
                     * end of the day
                     */
                    if (day.endTime.getHours() !== currentDayEnd.getHours()) {
                        endBlock = {
                            start: {
                                hours: day.endTime.getHours(),
                                minutes: day.endTime.getMinutes(),
                            },
                            end: {
                                hours: currentDayEnd.getHours(),
                                minutes: currentDayEnd.getMinutes(),
                            },
                            overlap: false,
                        };
                        dayAvailability.push(endBlock);
                    }
                    unavailableDates.push(dayAvailability);
                } else {
                    /**
                     * if day.startTime is an empty string, then the
                     * entire day is unavailable
                     */
                    unavailableDates.push([
                        {
                            start: {
                                hours: currentDayStart.getHours(),
                                minutes: currentDayStart.getMinutes(),
                            },
                            end: {
                                hours: currentDayEnd.getHours(),
                                minutes: currentDayEnd.getMinutes(),
                            },
                            overlap: false,
                        },
                    ]);
                }
            });

            if (noticeBlock1) {
                unavailableDates[shiftedNowIndex].push(noticeBlock1);
            }
            if (tomorrowBlock) {
                unavailableDates[shiftedTomorrowIndex].push(tomorrowBlock);
            }

            unavailableDates[shiftedNowIndex].push(upTillNowBlock);
        }

        // if showings exist, condense their times into an array
        if (showings && showings.length) {
            // var listingAvailabilityExists: boolean = true;
            // first day of the week (current day)
            var dayStart: any = new Date();
            var dayEnd: any = new Date();
            if (!unavailableDates.length) {
                unavailableDates = [[], [], [], [], [], [], []];
                // listingAvailabilityExists;
                dayStart.setHours(8, 0, 0, 0);
                dayEnd.setHours(20, 0, 0, 0);
            } else {
                dayStart.setHours(5, 0, 0, 0);
                dayEnd.setHours(21, 0, 0, 0);
            }
            //
            /**
             * for each showing, find the correct day of the week
             * and compare it to the time slot it should sit in
             */
            showings.map((showing: any, index: number) => {
                const start = showing.start;
                const end = showing.end;
                //

                //

                const slot = {
                    start: {
                        hours: start.getHours(),
                        minutes: start.getMinutes(),
                    },
                    end: {
                        hours: end.getHours(),
                        minutes: end.getMinutes(),
                    },
                    weekday: start.getDay(),
                    overlap: listing?.agentListing?.allowOverlap || false,
                };

                /**
                 * Shift the date because listing availability date time starts
                 * on monday, not on sunday
                 */

                var shiftedIndex = showing.start.getDay() - 1;
                if (shiftedIndex === -1) {
                    shiftedIndex = 6;
                }

                const unavailableDay = unavailableDates[shiftedIndex];
                /**
                 * if unavailableDay is not equal to -1, then there are
                 * already unavailable times for that day
                 */
                if (unavailableDay !== -1 && unavailableDay?.length) {
                    unavailableDates[unavailableDates.indexOf(unavailableDay)].push(slot);
                } else {
                    unavailableDates[unavailableDates.indexOf(unavailableDay)] = [slot];
                }
            });
        }

        if (!unavailableDates.length) {
            console.log('unconnected');
            unavailableDates = [[], [], [], [], [], [], []];
            unavailableDates[shiftedNowIndex].push(upTillNowBlock);
            console.log('unavailDates', unavailableDates);
        }
    }
    return unavailableDates;
};
