import { RemoteMongoClient, Stitch } from 'mongodb-stitch-browser-sdk';
import { COLLECTION, BROKERAGE_USERS, BROKERAGES } from './constants';

export function getDatabase() {
    if (process.env.REACT_APP_STITCH_DATABASE_SERVICE_NAME === undefined) {
        throw Error('Database service name is undefined');
    }
    if (process.env.REACT_APP_STITCH_DATABASE_NAME === undefined) {
        throw Error('Database name is undefined');
    }
    return Stitch.defaultAppClient
        .getServiceClient(
            RemoteMongoClient.factory,
            process.env.REACT_APP_STITCH_DATABASE_SERVICE_NAME,
        )
        .db(process.env.REACT_APP_STITCH_DATABASE_NAME);
}
export function getCollection(collectionName) {
    const mongoClient = Stitch.defaultAppClient.getServiceClient(
        RemoteMongoClient.factory,
        process.env.REACT_APP_STITCH_DATABASE_SERVICE_NAME,
    );
    const db = mongoClient.db(process.env.REACT_APP_STITCH_DATABASE_NAME);
    return db.collection(collectionName);
}
/**
 * Attempt to parse the error for its string message.
 * If that fails, fall back to error name or as a last resort, show generic error message.
 * @param stitchError Instance of error object `StitchServiceError` caught from API
 * @returns {string}
 */
export function parseStitchServiceError(stitchError: Error) {
    try {
        return JSON.parse(stitchError.message);
    } catch {
        if (stitchError?.message && typeof stitchError.message === 'string') {
            return stitchError.message;
        } else {
            return stitchError?.name || 'Something Went Wrong.';
        }
    }
}

export async function upsertBrokerageRecord(user: any, data: any) {
    const client = Stitch.defaultAppClient;
    client.auth.switchToUserWithId(user.id);
    const brokerages = getDatabase().collection('brokerages');
    const query = { stitchUserId: user.id };
    const options = { upsert: true };
    return brokerages.updateOne(query, { $set: data }, options);
}

export async function upsertAgentRecord(user: any, data: any) {
    const client = Stitch.defaultAppClient;
    client.auth.switchToUserWithId(user.id);
    const agents = getDatabase().collection('agents');
    const query = { stitchUserId: user.id };
    const options = { upsert: true };
    return agents.updateOne(query, { $set: data }, options);
}

/**
 * Call the given stitch function with its params
 * @param client Mongo Stitch Client returned from initialization
 * @param endpoint
 * @param params
 * @returns {Promise<*>}
 */
export function callStitchFunction(client: any, endpoint: any, ...params: any) {
    return client.callFunction(endpoint, params);
}

export async function getPhoto(path: string) {
    try {
        return await Stitch.defaultAppClient.callFunction('getPhoto', [path]);
    } catch (error) {
        console.log(error);
    }
}

/**
 * Performs a findOne operation on a named collection
 * with the given query and projection.
 *
 * @param collectionName The name of the collection to query against
 * @param query The query to provide to the findOne function.
 * @param projection
 * @returns {Promise<Document | null>}
 */
export async function findRecord(collectionName: string, query = {}, projection = {}) {
    if (!collectionName) {
        throw new Error('collectionName is required!');
    }
    const collection = getDatabase().collection(collectionName);
    return collection.findOne(query, projection);
}

/**
 * Performs a find operation on a named collection
 * with the given query and projection.
 *
 * @param collectionName The name of the collection to query against
 * @param query The query to provide to the find function.
 * @param projection
 * @returns {Promise<Document[] | null>}
 */
export async function findRecords(collectionName: string, query = {}, projection = {}) {
    if (!collectionName) {
        throw new Error('collectionName is required!');
    }
    const collection = getDatabase().collection(collectionName);
    return collection.find(query, projection).toArray();
}

export function fetchAdminAccount() {
    const user = Stitch.defaultAppClient.auth.user;
    if (user === undefined) {
        return null;
    } else {
        return getDatabase().collection(COLLECTION.Admins).findOne({
            userId: user.id,
        });
    }
}
