import { createSelector } from 'reselect';

export const getAuthState = (state: any) => state.auth || {};
export const getProfileState = (state: any) => state.profile || {};

export const getIsUserAuthenticated = createSelector(getAuthState, (auth) => auth.isAuthenticated);

export const getAdminAccount = createSelector(getAuthState, (auth) => auth.account);

// export const getAdminEmail= createSelector(
//     getAuthState,
//     (auth) => auth.account.profile.data.email,
// )
