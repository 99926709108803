import React from 'react';
import styles from './index.module.css';
import { useHistory, useLocation } from 'react-router-dom';

import { articles } from '../../domains/dash/Support/SupportDocs/articles';

const TrainingArticle = () => {
    const loc = useLocation();
    const strings = loc.pathname.split('/');
    const id = strings[strings.length - 1];
    const article = articles.find((o) => o?.id === id);
    console.log('id', id);
    console.log(`blog`, article);

    return (
        <div className={styles.center}>
            {article?.type === 'iframe' ? (
                <iframe className={styles.iframe} src={article?.link}></iframe>
            ) : (
                <img className={styles.iframe} src={article?.link} />
            )}
        </div>
    );
};
export default TrainingArticle;
