import { all, put, takeLatest, call } from 'redux-saga/effects';
import { STATUS } from '../../../../store/api/constants';
import {
    AGENT_SEARCH_ACTIONS,
    searchForAgentFailed,
    searchForAgentRequested,
    searchForAgentSucceeded,
} from './actions';
import { BSON, Stitch, StitchUser } from 'mongodb-stitch-browser-sdk';
import { callStitchFunction } from '../../../../store/api/sagas';

export function* searchAgents({ searchTerm }: ReturnType<typeof searchForAgentRequested>) {
    try {
        const client = Stitch.defaultAppClient;
        const projection = {
            firstName: 1,
            lastName: 1,
            brokerage: 1,
            email: 1,
            phoneNumber: 1,
            _id: 1,
            stitchUserId: 1,
            signUpCompleted: 1,
            profilePhotoUpload: 1,
            status: 1,
            markets: 1,
            badges: 1,
        };
        const agents = yield call(
            callStitchFunction,
            client,
            'textSearchAgents',
            searchTerm,
            projection,
        );
        yield put(searchForAgentSucceeded(agents));
        // yield put(searchForAgentRequested(searchTerm));
    } catch (error) {
        yield put(searchForAgentFailed(error));
    }
}

export default function* () {
    yield all([
        takeLatest(
            (action: any) =>
                action.type === AGENT_SEARCH_ACTIONS.search && action.status === STATUS.Requested,
            searchAgents,
        ),
    ]);
}
