import React, { useState } from 'react';

import classnames from 'classnames';
import styles from './index.module.css';
import { Button } from 'web-lib';

interface ButtonTabBarProps {
    buttonNames: any;
    setPage: Function;
    currPage: string;
    buttonStyles?: string;
}

const ButtonTabBar = ({ buttonNames, setPage, buttonStyles, currPage }: ButtonTabBarProps) => {
    return (
        <div className={styles.root}>
            {buttonNames.map((option: { name: string; page: string }) => (
                <Button
                    buttonText={option.name}
                    className={
                        currPage === option.page ? styles.selectedFilterOption : styles.filterOption
                    }
                    onClick={() => {
                        setPage(option.page);
                    }}
                    key={option.page}
                />
            ))}
        </div>
    );
};

ButtonTabBar.defaultProps = {
    containerStyles: null,
    photoURI: null,
};

export default ButtonTabBar;
