import React from 'react';
import { Col, Row } from 'react-grid-system';
import { useHistory } from 'react-router-dom';
import styles from './index.module.css';
import { articles } from './articles';
import { DocumentCards } from '../../../../components';

const SupportDocs = () => {
    const history = useHistory();
    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.row}>
                    {articles.map((article: any) => (
                        <DocumentCards file={article} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SupportDocs;
