import { OrderedMap } from 'immutable';
import { ObjectId } from 'mongodb';
import { BSON } from 'mongodb-stitch-browser-sdk';
import { ErrorObject, serializeError } from 'serialize-error';
import { initialState } from '../../../redux';
import { STATUS } from '../../../store/api/constants';
import { DataManipulationAction, DM_ACTION } from './actions';

export type DMState = {
    loading: boolean;
    brokerageSearchResults: any[];
    agentUsers: OrderedMap<BSON.ObjectId, any>;
    brokerageUsers: OrderedMap<BSON.ObjectId, any>;
    brokerages: OrderedMap<BSON.ObjectId, any>;
    selected: BSON.ObjectId | null;
    error: ErrorObject | null | Error | string;
    brokerageCreated: Object | null;
    contentCreated: boolean;
    badges: any;
    listings: any;
    listing: any;
    newBrokerageUser: any;
    clients: any;
};

const initialDMState: DMState = {
    loading: false,
    brokerageSearchResults: [],
    agentUsers: OrderedMap<BSON.ObjectId, any>(),
    brokerageUsers: OrderedMap<BSON.ObjectId, any>(),
    brokerages: OrderedMap<BSON.ObjectId, any>(),
    selected: null,
    error: null,
    brokerageCreated: false,
    contentCreated: false,
    newBrokerageUser: null,
    badges: [],
    listings: [],
    listing: null,
    clients: null,
};

const MAX_STORED_PER_MAP = 10;

function saveCreatedObject(map: OrderedMap<BSON.ObjectId, any>, id: ObjectId, newObject: any) {
    if (map.size > MAX_STORED_PER_MAP) {
        map = map.delete(map.keySeq().first());
    }
    return map.set(id, newObject);
}

export function reducerDM(state = initialDMState, action: DataManipulationAction): DMState {
    if (!Object.values(DM_ACTION).includes(action.type)) {
        return state;
    }
    if (action.type === DM_ACTION.Select) {
        return {
            ...state,
            selected: action.id,
        };
    }
    switch (action.status) {
        case STATUS.Succeeded:
            switch (action.type) {
                case DM_ACTION.CreateBrokerage:
                    return {
                        ...state,
                        loading: false,
                        selected: action.brokerage._id,
                        brokerages: saveCreatedObject(
                            state.brokerages,
                            action.brokerage._id,
                            action.brokerage,
                        ),
                        brokerageCreated: action.brokerage,
                    };
                case DM_ACTION.AddAgentContent:
                    return {
                        ...state,
                        loading: false,
                        contentCreated: true,
                    };
                case DM_ACTION.FetchBadges:
                    return {
                        ...state,
                        loading: false,
                        contentCreated: true,
                        badges: action.badges,
                    };
                case DM_ACTION.SearchListings:
                    /**
                     * wouldn't need to do this is we had the other version of action definitions...
                     * and could have multiple types for succeeded...
                     */
                    const { listings = null } = action;
                    var listing = null;
                    var stateListings = [...state.listings];
                    if (listings?.length > 1) {
                        stateListings = listings;
                    } else {
                        listing = listings;
                    }
                    return {
                        ...state,
                        loading: false,
                        contentCreated: true,
                        listings: stateListings,
                        listing: listing,
                    };
                case DM_ACTION.FetchAgentClients: {
                    return {
                        ...state,
                        loading: false,
                        clients: action.clients,
                    };
                }
                case DM_ACTION.CreateAgentUser:
                    return {
                        ...state,
                        loading: false,
                        selected: action.user._id,
                        agentUsers: saveCreatedObject(state.agentUsers, action.user._id, {
                            account: action.account,
                            user: action.user,
                        }),
                    };
                case DM_ACTION.CreateBrokerageUser:
                    return {
                        ...state,
                        loading: false,
                        selected: action.user._id,
                        brokerageUsers: saveCreatedObject(state.brokerageUsers, action.user._id, {
                            account: action.account,
                            user: action.user,
                        }),
                        newBrokerageUser: action.user,
                    };
                case DM_ACTION.FetchBrokerages:
                    return {
                        ...state,
                        loading: false,
                        brokerageSearchResults: action.brokerages,
                    };
                default:
                    return state;
            }

        case STATUS.Requested:
            return {
                ...state,
                loading: true,
                brokerageCreated: null,
                contentCreated: false,
                newBrokerageUser: null,
            };
        case STATUS.Failed:
            return {
                ...state,
                loading: false,
                brokerageCreated: null,
                contentCreated: false,
                error: serializeError(action.error),
            };

        default:
            return state;
    }
}
