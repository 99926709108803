import { image } from 'd3';
import React, { useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useHistory } from 'react-router-dom';
import styles from './index.module.css';

type AdminInfoProps ={
    profileImage: string;
    firstName: string;
    lastName: string;
    department: string;
    phoneNumber: string;
    email: string;
    devAgentId: string;
    stagingAgentId: string;
    prodAgentId: string;

}
const AdminInfo = ({profileImage, firstName, lastName, department, phoneNumber, email, devAgentId, stagingAgentId, prodAgentId}:AdminInfoProps) => {
    return (
        <div className={styles.root}>
            <div className={styles.row}>
                <div className={styles.columnFirst}>
                    <div> 
                        <img className={styles.image} src={profileImage}/>
                    </div>
                    <div>
                        <strong>
                            {firstName} {lastName}
                        </strong>
                    </div>
                    <div>
                        {phoneNumber}
                    </div>
                    <div>
                        {email}
                    </div>
                </div>
                <div className={styles.column} >
                    <div className={styles.textCor}>
                        <strong>Department: </strong>
                        {department}
                    </div>
                    <div className={styles.textCor}>
                        <strong>Dev ID: </strong>
                        {devAgentId}
                    </div>
                    <div>
                        <strong>Staging ID: </strong>
                        {stagingAgentId}
                    </div>
                    <div>
                        <strong>Production ID: </strong>
                        {prodAgentId}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminInfo;
