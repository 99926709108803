import React, { useEffect, useState } from 'react';
import { Row } from 'react-grid-system';
import { useForm } from 'react-hook-form';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { initialization, isAuthLoading, loginRequested } from '../../../redux/auth';
import background from '../../../Images/LoginImage.png';
import { Button } from '../../../components';
import styles from './index.module.css';
import check from '../../../Images/checkMark.svg';

const mapStateToProps = (state: any) => ({
    authLoading: isAuthLoading(state),
});

const mapDispatchToProps = {
    loginRequested: loginRequested,
    initialization: initialization,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

function LoginPage(args: ReduxProps) {
    const history = useHistory();
    const [loggedInUser, setLoggedInUser] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const { register, handleSubmit, errors } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
    });

    // on mount, initialize
    useEffect(() => {
        args.initialization();
    }, []);

    const onSubmit = ({ username, password }: { username: string; password: string }) => {
        args.loginRequested(username, password, history);
    };

    return (
        <Row className={styles.root}>
            <img className={styles.image} src={background} />
            <div className={styles.leftDiv}>
                <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                    <h1>Showingly Admin</h1>
                    <div className={styles.inputGroup}>
                        <input
                            id={'username'}
                            ref={register({
                                required: 'Email field is required',
                            })}
                            type={'username'}
                            name={'username'}
                            className={styles.formControl}
                            placeholder="User"
                        />
                        {/* {errors.email && <span className={styles.errorText}>{'Error'}</span>} */}
                    </div>
                    <div className={styles.inputGroup}>
                        <input
                            id={'password'}
                            ref={register({
                                required: 'Password field is required',
                            })}
                            type={'password'}
                            name={'password'}
                            className={styles.formControl}
                            placeholder="***********"
                        />
                        {/* {errors.password && <span className={styles.errorText}>{'Error'}</span>} */}
                    </div>
                    <Button
                        buttonText={args.authLoading ? '...' : 'Login'}
                        buttonAlias="Primary"
                        className={styles.buttonControl}
                    />
                    <div className={styles.checkdiv}>
                        <div
                            onClick={() => {
                                setRememberMe(!rememberMe);
                                localStorage.setItem('remember', (!rememberMe).toString());
                            }}
                            className={styles.checkbox}
                        >
                            {rememberMe && <img className={styles.check} src={check} />}
                        </div>
                        <div className={styles.remember}>Remember me on this device</div>
                    </div>
                </form>
            </div>
        </Row>
    );
}

export const ConnectedLoginPage = connect(mapStateToProps, mapDispatchToProps)(LoginPage);
