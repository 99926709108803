import React from 'react';
import { connect } from 'react-redux';
import styles from './index.module.css';
import GeneralInfo from '../Common/GeneralInfo';
import { getAgentLoading, getSelectedAgent } from '../selectors';
import {
    formatPhoneNumberForDisplay,
    formatEmailForDisplay,
    timeSince,
} from '../../../../../src/utils';

const mapStateToProps = (state: any) => ({
    loading: getAgentLoading(state),
    agent: getSelectedAgent(state),
});
type AgentViewerProps = {
    agent: any;
    loading: boolean;
};
const connector = connect(mapStateToProps);

export const AgentViewer = ({ agent, loading }: AgentViewerProps) => {
    return (
        <div className={styles.root}>
            <div className={styles.row}>
                <div className={styles.generalInfoDiv}>
                    <GeneralInfo
                        name={agent?.firstName + ' ' + agent?.lastName}
                        id={agent?.markets[0]?.agentMlsId}
                        market={agent?.markets[0]?.marketName}
                        mlsName={agent?.markets[0]?.mlsName}
                        status={agent?.status ? agent?.status : ''}
                        image={
                            agent?.profilePhotoUpload?.uri === undefined
                                ? ''
                                : agent?.profilePhotoUpload?.uri
                        }
                        loading={loading}
                        phone={formatPhoneNumberForDisplay(agent?.phoneNumber)}
                        email={formatEmailForDisplay(agent?.email)}
                        hasSubscription={agent?.subscription?.status === 'active'}
                        lastSession={timeSince(agent?.lastSession)}
                    />
                </div>
            </div>
        </div>
    );
};

export default connector(AgentViewer);
