import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ConnectedLoginPage } from './domains/auth/Login';

function UnauthenticatedApp() {
    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Switch>
                <Route exact path="/auth">
                    <ConnectedLoginPage />
                </Route>
                <Route
                    exact
                    path="*"
                    render={() => {
                        return <Redirect to="/auth" />;
                    }}
                />
            </Switch>
        </BrowserRouter>
    );
}

export default UnauthenticatedApp;
