export const articles = [
    {
        id: 'supportCalendar',
        title: 'Showingly Support Calendar',
        type: 'iframe',
        link:
            'https://docs.google.com/spreadsheets/d/e/2PACX-1vSMesIMKx2I3r6r7-1XPNTOO7L07IIgcudQ9wPdCPZiojIgEswGeU495hCDXwh_dk3IgszAZkVny4rD/pubhtml?widget=true&amp;headers=false',
    },
    {
        id: 'callFlowChart',
        title: 'Call Flow Charts',
        type: 'iframe',
        link:
            'https://docs.google.com/document/d/e/2PACX-1vTo3IvRCqp9gdp_xxy_a_exjRExHnPkMvPGXz_IEchgErg1Nj0MUGzMq30Pk4p8lMRfGgcMCGvCzQGT/pub?embedded=true',
    },
    {
        id: 'flowBasedTesting',
        title: 'Flow Based Testing',
        type: 'iframe',
        link:
            'https://docs.google.com/spreadsheets/d/1lqJDV2y3sO-PGsYDj-wdbbZ9IDPnWXO98wiuMGIrZmA/edit?usp=sharing',
    },
    {
        id: 'miamiSSOFlow',
        title: 'Miami SSO Flow Chart',
        type: 'img',
        link:
            'https://docs.google.com/drawings/d/e/2PACX-1vSm2aU6qEIWHNI5cBdEDKCuvlEaB8B9NdqOQfuPTDRUQE-Bd2CPnldYD-oCAJXQuw3_Baz4uwmEPBHU/pub?w=961&amp;h=600',
    },
];
