import React, { useState } from 'react';
import styles from './index.module.css';
import classnames from 'classnames';
import downArrow from '../../Images/Arrow-down.svg';

//Pass an array of strings to be used as options and function that can take a
//  parameter to be used to set something elsewhere in the search.

const Dropdown = ({ options, handleClick, className }) => {
    const primaryDrop = classnames(styles, className);

    const [displayMenu, setdisplayMenu] = useState(false);
    const [displayedOption, setDisplayedOption] = useState(options[0]);

    function showDropdown(option) {
        setdisplayMenu(true);
    }

    function hideDropdown(option) {
        setdisplayMenu(false);
    }

    function onHeaderClick() {
        displayMenu ? hideDropdown() : showDropdown();
    }

    //When an item is clicked
    // set the displayed option on the header item
    // use the supplied button to update the parent state
    // hide the dropdown
    function onItemClick(option) {
        setDisplayedOption(option);
        handleClick(option);
        hideDropdown();
    }

    return (
        <div>
            <div onClick={() => onHeaderClick()} className={styles.headButton}>
                {displayedOption}{' '}
                <img alt="down-arrow" src={downArrow} className={styles.iconStyle} />{' '}
            </div>

            {displayMenu ? (
                <div className={styles.listBox}>
                    {options.map((option) => (
                        <div
                            key={option}
                            onClick={() => onItemClick(option)}
                            className={styles.listItem}
                        >
                            {option}
                        </div>
                    ))}
                </div>
            ) : null}
        </div>
    );
};

export default Dropdown;
