import { ObjectID } from 'mongodb';
import { BSON } from 'mongodb-stitch-browser-sdk';
import { STATUS } from '../../../store/api/constants';

export enum SEARCH_ACTION {
    TextSearchAgents = '[Search Action] Text Search Agents',
    TextSearchListings = '[Search Action] Text Search Listings',
    TextSearchBrokerages = '[Search Action] Text Search Brokerages',
    // TextSearchConsumers = '[Search Action] Text Search Consumers',
    FetchShowingAvailability = '[Search Action], Fetch Showing Availability',
    ClearShowingAvailability = '[Search Action], Clear Showing Availability',
    ClearShowingScheduled = '[Search Action], Clear Scheduled Showing ',
    // FetchBadges = '[All Searches] Fetch Badges',
    // UpdateAgent = '[All Searches] Badge Interaction',
    // CreateShowing = '[Search Action] Create Showing',
    // FetchAgentClients = '[All Searches] Fetch Agent Clients',
    ScheduleShowing = '[Search Action] Schedule Showing',
    SetSelectedBrokerage = '[Search Action] Set Selected Brokerage',
    UpdateSelectedBrokerage = '[Search Action] Updated Selected Brokerage',
    FetchBrokerageRoster = '[Search Action] Fetch Brokerage Roster',
    FetchBrokerageAdmins = '[Search Action] Fetch Brokerage Admins',
    SetAgentSelected = '[Search Action] Set Agent',
}

export function searchAgentsRequested(searchText: string, marketName: string) {
    return {
        type: SEARCH_ACTION.TextSearchAgents,
        status: STATUS.Requested,
        searchText,
        marketName,
    } as const;
}

export function searchAgentsSucceeded(agents: any[]) {
    return {
        type: SEARCH_ACTION.TextSearchAgents,
        status: STATUS.Succeeded,
        agents,
    } as const;
}

export function searchAgentsFailed(error: Error) {
    return {
        type: SEARCH_ACTION.TextSearchAgents,
        status: STATUS.Failed,
        error,
    } as const;
}

export type SearchAgentActions =
    | ReturnType<typeof searchAgentsRequested>
    | ReturnType<typeof searchAgentsSucceeded>
    | ReturnType<typeof searchAgentsFailed>;

// ==============================================================

export function searchListingsRequested(searchText: string, marketId: string) {
    return {
        type: SEARCH_ACTION.TextSearchListings,
        status: STATUS.Requested,
        searchText,
        marketId,
    } as const;
}

export function searchListingsSucceeded(listings: any[]) {
    return {
        type: SEARCH_ACTION.TextSearchListings,
        status: STATUS.Succeeded,
        listings,
    } as const;
}

export function searchListingsFailed(error: Error) {
    return {
        type: SEARCH_ACTION.TextSearchListings,
        status: STATUS.Failed,
        error,
    } as const;
}

export type SearchListingActions =
    | ReturnType<typeof searchListingsRequested>
    | ReturnType<typeof searchListingsSucceeded>
    | ReturnType<typeof searchListingsFailed>;

// ==============================================================
export function searchBrokeragesRequested(searchText: string, marketId: string) {
    return {
        type: SEARCH_ACTION.TextSearchBrokerages,
        status: STATUS.Requested,
        searchText,
        marketId,
    } as const;
}
export function searchBrokeragesSucceeded(brokerages: any[]) {
    return {
        type: SEARCH_ACTION.TextSearchBrokerages,
        status: STATUS.Succeeded,
        brokerages,
    } as const;
}

export function searchBrokeragesFailed(error: Error) {
    return {
        type: SEARCH_ACTION.TextSearchBrokerages,
        status: STATUS.Failed,
        error,
    } as const;
}

export type SearchBrokeragesAction =
    | ReturnType<typeof searchBrokeragesRequested>
    | ReturnType<typeof searchBrokeragesSucceeded>
    | ReturnType<typeof searchBrokeragesFailed>;
// ==============================================================

export function fetchShowingAvailabilityRequested(listingId: BSON.ObjectId) {
    return {
        type: SEARCH_ACTION.FetchShowingAvailability,
        status: STATUS.Requested,
        listingId,
    } as const;
}
export function fetchShowingAvailabilitySucceeded(unavailableDates: any[] = []) {
    return {
        type: SEARCH_ACTION.FetchShowingAvailability,
        status: STATUS.Succeeded,
        unavailableDates,
    } as const;
}
export function fetchShowingAvailabilityFailed(error: string) {
    return {
        type: SEARCH_ACTION.FetchShowingAvailability,
        status: STATUS.Failed,
        error,
    } as const;
}
export type ShowingAvailabilityActions =
    | ReturnType<typeof fetchShowingAvailabilityRequested>
    | ReturnType<typeof fetchShowingAvailabilitySucceeded>
    | ReturnType<typeof fetchShowingAvailabilityFailed>;

// ==============================================================

export function scheduleShowingRequested(
    listingId: any,
    startTime: Date,
    endTime: Date,
    agent: any,
    offset: any,
) {
    return {
        type: SEARCH_ACTION.ScheduleShowing,
        status: STATUS.Requested,
        listingId,
        startTime,
        endTime,
        agent,
        offset,
    } as const;
}
export function scheduleShowingSucceeded(showingCreated: any) {
    return {
        type: SEARCH_ACTION.ScheduleShowing,
        status: STATUS.Succeeded,
        showingCreated,
    } as const;
}
export function scheduleShowingFailed(error: Error | null | string) {
    return {
        type: SEARCH_ACTION.ScheduleShowing,
        status: STATUS.Failed,
        error,
    } as const;
}
export type ScheduleShowingActions =
    | ReturnType<typeof scheduleShowingRequested>
    | ReturnType<typeof scheduleShowingSucceeded>
    | ReturnType<typeof scheduleShowingFailed>;

export function clearShowingAvailability() {
    return {
        type: SEARCH_ACTION.ClearShowingAvailability,
    } as const;
}
export function clearShowingScheduled() {
    return {
        type: SEARCH_ACTION.ClearShowingScheduled,
    } as const;
}

export function setSelectedBrokerage(brokerage: any) {
    return {
        type: SEARCH_ACTION.SetSelectedBrokerage,
        brokerage,
    } as const;
}
export function updateSelectedBrokerageRequested(
    brokerageId: BSON.ObjectId,
    brokerageStatus: any,
    superAdminId: BSON.ObjectID,
    adminsToUpdate: Array<BSON.ObjectID>,
) {
    return {
        type: SEARCH_ACTION.UpdateSelectedBrokerage,
        status: STATUS.Requested,
        brokerageId,
        brokerageStatus,
        superAdminId,
        adminsToUpdate,
    } as const;
}
export function updateSelectedBrokerageSucceeded(newStatus: string) {
    if (newStatus === 'verified') {
        window.alert('Lets`s get this 🍞 🥖');
    }
    return {
        type: SEARCH_ACTION.UpdateSelectedBrokerage,
        status: STATUS.Succeeded,
    } as const;
}
export function updateSelectedBrokerageFailed(error: Error | null | string) {
    return {
        type: SEARCH_ACTION.UpdateSelectedBrokerage,
        status: STATUS.Failed,
        error,
    } as const;
}

export function fetchBrokerageRosterRequested(brokerageId: BSON.ObjectId) {
    return {
        type: SEARCH_ACTION.FetchBrokerageRoster,
        status: STATUS.Requested,
        brokerageId,
    } as const;
}
export function fetchBrokerageRosterSucceeded(roster: Array<any>) {
    return {
        type: SEARCH_ACTION.FetchBrokerageRoster,
        status: STATUS.Succeeded,
        roster,
    } as const;
}
export function fetchBrokerageRosterFailed(error: Error | null | string) {
    return {
        type: SEARCH_ACTION.FetchBrokerageRoster,
        status: STATUS.Failed,
        error,
    } as const;
}

export function fetchBrokerageAdminsRequested(brokerageId: BSON.ObjectId) {
    return {
        type: SEARCH_ACTION.FetchBrokerageAdmins,
        status: STATUS.Requested,
        brokerageId,
    } as const;
}
export function fetchBrokerageAdminsSucceeded(admins: any[]) {
    return {
        type: SEARCH_ACTION.FetchBrokerageAdmins,
        status: STATUS.Succeeded,
        admins,
    } as const;
}
export function fetchBrokerageAdminsFailed(error: Error | null | string) {
    return {
        type: SEARCH_ACTION.FetchBrokerageAdmins,
        status: STATUS.Failed,
        error,
    } as const;
}

export function setAgentSelected(agent) {
    return {
        type: SEARCH_ACTION.SetAgentSelected,
        status: STATUS.Requested,
        agent,
    } as const;
}

export type ViewingActions =
    | ReturnType<typeof setSelectedBrokerage>
    | ReturnType<typeof setAgentSelected>
    | ReturnType<typeof fetchBrokerageRosterRequested>
    | ReturnType<typeof fetchBrokerageRosterSucceeded>
    | ReturnType<typeof fetchBrokerageRosterFailed>
    | ReturnType<typeof fetchBrokerageAdminsRequested>
    | ReturnType<typeof fetchBrokerageAdminsSucceeded>
    | ReturnType<typeof fetchBrokerageAdminsFailed>
    | ReturnType<typeof updateSelectedBrokerageRequested>
    | ReturnType<typeof updateSelectedBrokerageSucceeded>
    | ReturnType<typeof updateSelectedBrokerageFailed>;
