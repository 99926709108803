import React, { useEffect, useState } from 'react';
import styles from './index.module.css';

type TableHeaderProps = {
    type: string;
};

export const TableHeader = ({ type }: TableHeaderProps) => {
    return (
        <div className={styles.tableHead}>
            <div className={styles.nameContainer}>Name</div>
            <div className={styles.first}>{type === 'agent' ? 'Phone Number' : 'Brokerage ID'}</div>
            <div className={styles.second}>
                <div>{type === 'agent' ? 'Email' : 'Status'}</div>
            </div>

            <div className={styles.third}>{type === 'agent' ? 'Agent ID' : 'Agent Count'}</div>

            <p className={styles.profileContainer}></p>
        </div>
    );
};

export default TableHeader;
