import { all, put, takeLatest, call, select } from 'redux-saga/effects';
import {
    STATUS,
    LISTINGS_COLLECTION,
    SHOWING_REQUESTS,
    AGENTS,
    BROKERAGES,
    BROKERAGE_USERS,
} from '../../../store/api/constants';
import * as Actions from './actions';
import { BSON, Stitch, StitchUser } from 'mongodb-stitch-browser-sdk';
import {
    callStitchFunction,
    findRecord,
    findRecords,
    parseStitchServiceError,
} from '../../../store/api/sagas';
import { getClient, getStitchUser, getStitchUserId } from '../../../redux';

export function* searchAgents({
    searchText,
    marketName,
}: ReturnType<typeof Actions.searchAgentsRequested>) {
    try {
        const client = Stitch.defaultAppClient;
        const agents = yield call(
            callStitchFunction,
            client,
            'textSearchAgents',
            searchText,
            null,
            null,
            marketName,
        );
        yield put(Actions.searchAgentsSucceeded(agents));
    } catch (error) {
        yield put(Actions.searchAgentsFailed(error));
        window.alert('Could not search for agents  🏃🏼‍♀️ 🏃🏼 🏃🏼‍♂️');
    }
}

export function* searchListings({
    searchText,
    marketId,
}: ReturnType<typeof Actions.searchListingsRequested>) {
    try {
        const client = Stitch.defaultAppClient;
        const listingsLimit = 20;
        const listings = yield call(
            callStitchFunction,
            client,
            'textSearchListings',
            searchText,
            true, //is extra data
            null, //brokerageId,
            listingsLimit,
            new BSON.ObjectID(marketId),
        );
        yield put(Actions.searchListingsSucceeded(listings));
    } catch (error) {
        yield put(Actions.searchListingsFailed(error));
        window.alert('Oh no! Could not search listings 👩🏽‍🚒 🚒');
    }
}

export function* searchBrokerages({
    searchText,
    marketId,
}: ReturnType<typeof Actions.searchBrokeragesRequested>) {
    try {
        const client = Stitch.defaultAppClient;
        const brokerages = yield call(
            callStitchFunction,
            client,
            'textSearchBrokerage',
            searchText,
            new BSON.ObjectID(marketId),
        );
        if (brokerages) yield put(Actions.searchBrokeragesSucceeded(brokerages));
        else window.alert('Failed to fetch brokerages 📞📞');
    } catch (error) {
        yield put(Actions.searchBrokeragesFailed(error));
        window.alert('Oh Jeez Rick 📞📞');
    }
}

export function* fetchShowingAvailability({
    listingId,
}: ReturnType<typeof Actions.fetchShowingAvailabilityRequested>) {
    try {
        const client = Stitch.defaultAppClient;
        const firstDate: any = new Date();
        var lastDate: any = new Date();
        lastDate.setHours(0, 0, 0, 0);
        lastDate.setDate(lastDate.getDate() + 7);

        const scheduledShowings = yield call(
            callStitchFunction,
            client,
            'runGeneralQuery',
            SHOWING_REQUESTS,
            {
                listingId,
                /**
                 * only if allowOverlap is not true on a connected listing
                 */
                'listing.agentListing.listingId': { $exists: true },
                'listing.agentListing.allowOverlap': { $ne: true },

                start: {
                    $gte: firstDate,
                },
                end: {
                    $lt: lastDate,
                },
                status: { $nin: ['denied', 'cancelled', 'canceled'] },
            },
            {
                start: 1,
                end: 1,
                listing: 1,
            },
        );

        yield put(Actions.fetchShowingAvailabilitySucceeded(scheduledShowings));
    } catch (error) {
        yield put(Actions.fetchShowingAvailabilityFailed(error));
        yield call(alert, {
            type: 'error',
            message: 'There was an issue in retrieving already scheduled showings.',
        });
        window.alert('Whoops, unable to grab the availability for this listing. 📅 ');
    }
}
export function* scheduleShowing({
    listingId,
    startTime,
    endTime,
    agent,
    offset,
}: ReturnType<typeof Actions.scheduleShowingRequested>) {
    try {
        const client = yield select(getClient);

        const showingScheduled = yield call(
            callStitchFunction,
            client,
            'createShowing',
            'agent',
            listingId, // function will convert to objectId
            startTime,
            endTime,
            null, // client
            //7,
            offset, //offset
            null, // delegationPrice
            agent.stitchUserId,
        );
        if (showingScheduled) {
            yield put(Actions.scheduleShowingSucceeded(showingScheduled));
        }
    } catch (error) {
        yield put(Actions.scheduleShowingFailed(error));
        window.alert('Big oof, was unable to schedule the showing 🤷‍♀️🤷‍♀️🤷‍♀️');
    }
}

export function* fetchBrokerageRoster({
    brokerageId,
}: ReturnType<typeof Actions.fetchBrokerageRosterRequested>) {
    try {
        const client = Stitch.defaultAppClient;
        const agents = yield call(
            callStitchFunction,
            client,
            'runGeneralQuery',
            AGENTS,
            { 'brokerage._id': brokerageId }, // match
            null, // projection
        );
        if (agents) {
            yield put(Actions.fetchBrokerageRosterSucceeded(agents));
        } else window.alert('Unable to fetch brokerage roster   📜 📜');
    } catch (error) {
        yield put(Actions.fetchBrokerageRosterFailed(error));
        window.alert('Unable to fetch brokerage roster 📜 📜 📜 📜');
    }
}

export function* fetchBrokerageAdmins({
    brokerageId,
}: ReturnType<typeof Actions.fetchBrokerageAdminsRequested>) {
    try {
        const client = Stitch.defaultAppClient;
        const admins = yield call(
            callStitchFunction,
            client,
            'runGeneralQuery',
            BROKERAGE_USERS,
            { brokerageId: brokerageId }, // match
            null, // projection
        );
        if (admins) {
            yield put(Actions.fetchBrokerageAdminsSucceeded(admins));
        } else
            window.alert('Unable to get brokerage admins or there may not be any, who knows 🗄  🗄');
    } catch (error) {
        yield put(Actions.fetchBrokerageAdminsFailed(error));
        window.alert('Unable to get brokerage admins, get beanised  🗄 🖇 🗄 🖇 🗄');
    }
}

export function* updateSelectedBrokerage({
    brokerageId,
    brokerageStatus,
    superAdminId,
    adminsToUpdate,
}: ReturnType<typeof Actions.updateSelectedBrokerageRequested>) {
    const client = Stitch.defaultAppClient;
    const args = [brokerageId, brokerageStatus, superAdminId, adminsToUpdate];
    try {
        const brokerageUpdate = yield call(
            callStitchFunction,
            client,
            'validateSystemUser',
            'verifyBrokerage',
            args,
        );
        if (brokerageUpdate) {
            const brokerage = yield call(
                callStitchFunction,
                client,
                'runGeneralQuery',
                BROKERAGES,
                { _id: brokerageId }, // match
                null, // projection
            );
            if (brokerage) {
                yield put(Actions.setSelectedBrokerage(brokerage[0]));
                yield put(Actions.fetchBrokerageAdminsRequested(brokerage[0]._id));
                yield put(Actions.updateSelectedBrokerageSucceeded(brokerageStatus));
            }
        }
    } catch (error) {
        yield put(Actions.updateSelectedBrokerageFailed(error));
        window.alert('Unable to Update 🅱️rockerij 🥦 🚷 🚯');
    }
}

export function* sagaSearch() {
    yield all([
        takeLatest(
            (action: any) =>
                action.type === Actions.SEARCH_ACTION.TextSearchAgents &&
                action.status === STATUS.Requested,
            searchAgents,
        ),
        takeLatest(
            (action: any) =>
                action.type === Actions.SEARCH_ACTION.TextSearchListings &&
                action.status === STATUS.Requested,
            searchListings,
        ),
        takeLatest(
            (action: any) =>
                action.type === Actions.SEARCH_ACTION.TextSearchBrokerages &&
                action.status === STATUS.Requested,
            searchBrokerages,
        ),
        takeLatest(
            (action: any) =>
                action.type === Actions.SEARCH_ACTION.FetchShowingAvailability &&
                action.status === STATUS.Requested,
            fetchShowingAvailability,
        ),
        takeLatest(
            (action: any) =>
                action.type === Actions.SEARCH_ACTION.ScheduleShowing &&
                action.status === STATUS.Requested,
            scheduleShowing,
        ),
        takeLatest(
            (action: any) =>
                action.type === Actions.SEARCH_ACTION.FetchBrokerageRoster &&
                action.status === STATUS.Requested,
            fetchBrokerageRoster,
        ),
        takeLatest(
            (action: any) =>
                action.type === Actions.SEARCH_ACTION.FetchBrokerageAdmins &&
                action.status === STATUS.Requested,
            fetchBrokerageAdmins,
        ),
        takeLatest(
            (action: any) =>
                action.type === Actions.SEARCH_ACTION.UpdateSelectedBrokerage &&
                action.status === STATUS.Requested,
            updateSelectedBrokerage,
        ),
    ]);
}
