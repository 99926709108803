import { createSelector } from 'reselect';
import { Searches } from './reducers';

export const getSearchState = (state: { searches: Searches }) => state.searches || {};

export const getLoading = createSelector(getSearchState, (searches: any) => searches.loading);
export const getAgents = createSelector(getSearchState, (searches: any) => searches.agents || {});
export const getListings = createSelector(
    getSearchState,
    (searches: any) => searches.listings || {},
);
export const getBrokerages = createSelector(
    getSearchState,
    (searches: any) => searches.brokerages || {},
);
export const getUnavailableDates = createSelector(
    getSearchState,
    (searches: any) => searches.unavailableDates,
);
export const getScheduledShowing = createSelector(
    getSearchState,
    (searches: any) => searches.showingCreated,
);
export const getShowingSuccessfullyCreated = createSelector(
    getSearchState,
    (searches: any) => searches.showingSuccessfullyCreated,
);
export const getSelectedBrokerage = createSelector(
    getSearchState,
    (searches: any) => searches.selectedViewingBrokerage,
);
export const getBrokerageRoster = createSelector(
    getSearchState,
    (searches: any) => searches.brokerageRoster,
);
export const getBrokerageAdmins = createSelector(
    getSearchState,
    (searches: any) => searches.brokerageAdmins,
);
export const getAgentLoading = createSelector(
    getSearchState,
    (searches: any) => searches.loadingAgent,
);
export const getSelectedAgent = createSelector(
    getSearchState,
    (searches: any) => searches.selectedViewingAgent,
);
