import React, { useEffect, useState, useRef } from 'react';
import { Button, Loader, SearchBar } from 'web-lib';
import { BSON } from 'mongodb-stitch-browser-sdk';
import { DateTime } from 'luxon';

import {
    getShowingDatesForDisplay,
    getShowingTimeForDisplay,
    getAvailableShowingTimes,
} from '../../utils/dateTime';
import {
    formatPhoneNumberForDisplay,
    formatEmailForDisplay,
    useOutsideClick,
} from '../../utils/index';

import { Assets } from 'asset-lib/src/index';
import styles from './index.module.css';

type DateObj = {
    day: string;
    date: string;
    month: any;
};

interface ScheduleShowingModalProps {
    listingId: BSON.ObjectId;
    agentListing?: any;
    loading?: boolean;
    marketName: string;
    showingSuccessfullyCreated: boolean;
    unavailableDates: any[];
    searchedAgents?: any[];
    mlsId: string;
    showingCreated: any;
    exitOnClick?: Function;
    onClickSchedule: Function;
    searchAgents: Function;
    clearShowingAvailability?: Function;
    clearShowingScheduled?: Function;
}

const ScheduleShowingModal = ({
    listingId,
    agentListing,
    mlsId,
    marketName,
    loading = false,
    showingSuccessfullyCreated,
    showingCreated,
    unavailableDates,
    searchedAgents = [],
    exitOnClick = () => {},
    onClickSchedule,
    searchAgents,
    clearShowingAvailability = () => {},
    clearShowingScheduled = () => {},
}: ScheduleShowingModalProps) => {
    const dates = getShowingDatesForDisplay();
    const times = getShowingTimeForDisplay();
    const availableShowingTimes = getAvailableShowingTimes(agentListing !== null);

    // contains showing times with times the user is unable to schedule removed
    const [availableShowingTimesFiltered, setAvailableShowingTimesFiltered] = useState(null);
    const [selectedDay, setSelectedDay] = useState<number>(0);
    const PIXELS_PER_15_MINUTES = 17.5;
    const PIXELS_PER_HOUR = PIXELS_PER_15_MINUTES * 4;
    let maxDuration = agentListing ? agentListing?.maxDuration : 120;
    let noticeRequired = agentListing ? agentListing?.noticeRequired : 0;

    const photoBucket = process.env.REACT_APP_PHOTO_BUCKET_NAME;
    const photoUrl = `https://${photoBucket}.s3.amazonaws.com/`;
    const [scheduleData, setScheduleData] = useState({
        date: dates[0],
        index: 0, // <- used to conditionally render unavailable times,
        listingId: listingId,
        time: availableShowingTimes[0],
        duration: '15min',
        agent: {
            _id: '',
            email: '',
            agentId: '',
            firstName: '',
            lastName: '',
            stitchUserId: '',
            phoneNumber: '',
            markets: [],
        },
    });
    let canSchedule = false;

    const [renderShowingTimeDropdown, setRenderShowingTimeDropdown] = useState<boolean>(false);
    const [renderDurationDropdown, setRenderDurationDropdown] = useState<boolean>(false);
    const [renderAgentSearch, setRenderAgentSearch] = useState<boolean>(false);
    const [agentSearchString, setAgentSearchString] = useState<string>('');
    const [currentSearchedAgents, setCurrentSearchedAgents] = useState<any[]>();

    const wrapperRef = useRef(null);

    // search for clients when the user enters any text
    useEffect(() => {
        if (agentSearchString.length) {
            searchAgents(agentSearchString, marketName);
        } else {
            setCurrentSearchedAgents([]);
        }
    }, [agentSearchString]);
    useEffect(() => {
        setCurrentSearchedAgents(searchedAgents);
    }, [searchedAgents]);

    // remove unavailableDate time options from the availableshowingtimes array
    // i.e. if we block scheduling from 7am-9:45am, we want to remove all options less than 10am
    // remove unavailableDate time options from the availableshowingtimes array
    // i.e. if we block scheduling from 7am-9:45am, we want to remove all options less than 10am
    useEffect(() => {
        if (unavailableDates[scheduleData.index]?.length) {
            let availableShowingTimesToFilter = availableShowingTimes;

            unavailableDates[scheduleData.index].map(
                (time: {
                    start: { hours: number; minutes: number };
                    end: { hours: number; minutes: number };
                }) => {
                    let endHour = time.end.hours >= 21 ? 21 : time.end.hours; // handle case in which end hours is later than 9pm
                    const startTimeToIndex = DateTime.fromMillis(Date.now())
                        .set({ hour: time.start.hours, minute: time.start.minutes, second: 0 })
                        .toFormat('h:mma');

                    const endTimeToIndex = DateTime.fromMillis(Date.now())
                        .set({ hour: endHour, minute: time.end.minutes, second: 0 })
                        .toFormat('h:mma');

                    const startIndex = availableShowingTimesToFilter.indexOf(startTimeToIndex);
                    const endIndex = availableShowingTimesToFilter.indexOf(endTimeToIndex);

                    availableShowingTimesToFilter = availableShowingTimesToFilter.filter(
                        (time: string, index: number) => !(index >= startIndex && index < endIndex),
                    );
                },
            );

            // if the last available time is 9:00PM and the user can't schedule to 8:45pm, just delete the 9pm option
            if (
                availableShowingTimesToFilter[availableShowingTimesToFilter.length - 1] ===
                    '9:00PM' &&
                availableShowingTimesToFilter[availableShowingTimesToFilter.length - 2] !== '8:45PM'
            ) {
                availableShowingTimesToFilter.pop();
            }
            setAvailableShowingTimesFiltered(availableShowingTimesToFilter);
        } else {
            setAvailableShowingTimesFiltered(availableShowingTimes);
            // setScheduleData({ ...scheduleData, time: availableShowingTimes[0] });
        }
    }, [scheduleData.index]);
    const getDurationOptions = () => {
        switch (maxDuration) {
            case 15:
                return ['15min'];
            case 30:
                return ['15min', '30min'];
            case 60:
                return ['15min', '30min', '1hr'];
            default:
                return ['15min', '30min', '1hr', '2hr'];
        }
    };
    let durationOptions = getDurationOptions();
    const getListingStyle = () => {
        let height;
        switch (scheduleData.duration) {
            case '15min':
                {
                    height = 17.5;
                }
                break;
            case '30min':
                {
                    height = 35;
                }
                break;
            case '1hr':
                {
                    height = 70;
                }
                break;
            case '2hr':
                {
                    height = 140;
                }
                break;
        }

        let top = 0;
        let currentTime = scheduleData.time.split(':');
        let pmOffset = parseInt(currentTime[0]) !== 12 && currentTime[1].charAt(2) === 'P' ? 12 : 0; // add 12 if the time is in the afternoon
        let startHour = parseInt(currentTime[0]) + pmOffset;
        let startMinute = parseInt(currentTime[1].substring(0, 2));
        top += (startHour - 5) * PIXELS_PER_HOUR + (startMinute / 15) * PIXELS_PER_15_MINUTES;

        return {
            height: `${height}px`,
            top: `${top + 10}px`,
            width: '550px',
        };
    };
    const DetailsLabel = ({ label, value }: { label?: string; value?: string }) => {
        return (
            <div className={styles.detailsLabel}>
                <div className={styles.labelconstant}>{label}&nbsp;</div>
                <div className={styles.labeltext}>{value}</div>
            </div>
        );
    };
    // calculate the height, width, and the top offset of the times the user is not able to schedule for

    const getUnavailableTimeStyle = (start: any, end: any) => {
        const date = new Date();
        console.log('date', date);
        const duration = ((end?.hours - start?.hours) * 60 - start?.minutes + end?.minutes) / 30;
        let height = duration * 35.5;
        let top = 0;
        top +=
            (start?.hours - 5) * PIXELS_PER_HOUR +
            (start?.minutes / 15) * (PIXELS_PER_15_MINUTES + 6);
        return {
            height: `${height}px`,
            top: `${top + 10}px`,
            width: '550px',
        };
    };
    const getStartAndEndTime = () => {
        const timeSplit = scheduleData.time.split(':');
        let startHour = parseInt(timeSplit[0]);
        if (timeSplit[1].charAt(2) === 'P' && timeSplit[0] !== '12') startHour += 12;
        let startMinute = parseInt(timeSplit[1].substring(0, 2));

        let endHour = startHour;
        let endMinute = startMinute;
        switch (scheduleData.duration) {
            case '15min':
                {
                    endMinute += 15;
                }
                break;
            case '30min':
                {
                    endMinute += 30;
                }
                break;
            case '1hr':
                {
                    endHour += 1;
                }
                break;
            case '2hr':
                {
                    endHour += 2;
                }
                break;
        }
        let startTime = DateTime.fromMillis(new Date().getTime())
            .set({
                month: scheduleData.date.month + 1,
                day: parseInt(scheduleData.date.date),
                hour: startHour,
                minute: startMinute,
                second: 0,
                millisecond: 0,
            })
            .toJSDate();
        let endTime = DateTime.fromMillis(new Date().getTime())
            .set({
                month: scheduleData.date.month + 1,
                day: parseInt(scheduleData.date.date),
                hour: endHour,
                minute: endMinute,
                second: 0,
                millisecond: 0,
            })
            .toJSDate();

        return { startTime, endTime };
    };
    // helper function for 'canScheduleThisTimeSlot' to determine if a number is inside a range of numbers
    //n = event length
    //a = start time
    //b = end time
    const isBetween = (n: number, a: number, b: number) => {
        return (n - a) * (n - b) < 0;
    };

    // helper function for 'canScheduleThisTimeSlot' to determine the start/end time range for the user's current schedule range
    // returns the time range of the start/end range in the same format of { showingStart: Number, showingEnd: Number }
    // i.e. a uesr scheduling a showing with a start at 2:30pm - 3:30pm would return { showingStart: 870, showingEnd: 930 }
    const getUsersCurrentRange = () => {
        let currentTimeSplit = scheduleData.time.split(':');
        let currentHour = parseInt(currentTimeSplit[0]);
        if (currentTimeSplit[1].charAt(2) === 'P' && currentTimeSplit[0] !== '12')
            currentHour += 12;
        let currentMinute = parseInt(currentTimeSplit[1].substring(0, 2));

        let thisShowingRangeStart = currentHour * 60 + currentMinute;
        let thisShowingRangeEnd = thisShowingRangeStart;
        switch (scheduleData.duration) {
            case '15min':
                {
                    thisShowingRangeEnd += 15;
                }
                break;
            case '30min':
                {
                    thisShowingRangeEnd += 30;
                }
                break;
            case '1hr':
                {
                    thisShowingRangeEnd += 60;
                }
                break;
            case '2hr':
                {
                    thisShowingRangeEnd += 120;
                }
                break;
        }

        return {
            showingStart: thisShowingRangeStart,
            showingEnd: thisShowingRangeEnd,
        };
    };
    const canScheduleThisTimeSlot = () => {
        let canSchedule = true;
        const { showingStart, showingEnd } = getUsersCurrentRange();

        if (
            !unavailableDates ||
            unavailableDates?.length < 1 ||
            unavailableDates[scheduleData.index] === -1
        ) {
            return canSchedule;
        }
        if (!scheduleData.agent.firstName) {
            canSchedule = false;
        }

        // convert start and end to their total minutes in the current day
        // if the minutes of the current "This showing" modal overlap the time of the unnavailable date range, return false
        unavailableDates[scheduleData.index]?.map(({ start, end }: any) => {
            let startRange = start.hours * 60 + start.minutes;
            let endRange = end.hours * 60 + end.minutes;

            if (
                isBetween(showingStart, startRange, endRange) ||
                isBetween(showingEnd, startRange, endRange)
            ) {
                canSchedule = false;
            }
        });
        return canSchedule;
    };

    const getAgentListingInfo = () => {
        const status = agentListing ? 'Connected' : 'Not Connected';
        const type = agentListing?.type;
        const notice =
            noticeRequired === 1440
                ? '1 Day'
                : noticeRequired === 120
                ? '2 Hours'
                : noticeRequired === 60
                ? '1 Hour'
                : 'None';
        const durr =
            maxDuration === 120
                ? '2 Hours'
                : maxDuration === 60
                ? '1 Hour'
                : maxDuration === 30
                ? '30 Minutes'
                : maxDuration === 15
                ? '15 Minutes'
                : '--';
        const listingType =
            type === 'accompanied'
                ? 'Accompanied'
                : type === 'appt_required'
                ? 'Appointment Required'
                : type === 'go_and_show'
                ? `Go n' Show`
                : '--';

        return (
            <div className={styles.rightSections}>
                <h2 className={styles.pickATimeHeader}>Listing Info - {status}</h2>

                <DetailsLabel label="ListingId: " value={mlsId} />
                {agentListing && (
                    <>
                        <DetailsLabel label="Type: " value={listingType} />
                        <DetailsLabel label="Notice Required: " value={notice} />
                        <DetailsLabel label="Max Duration: " value={durr} />
                    </>
                )}
            </div>
        );
    };

    return (
        <div className={styles.root} ref={wrapperRef}>
            <img
                src={Assets.Svgs.WebV3CloseButton}
                alt={'exit'}
                className={styles.GrayX}
                onClick={() => {
                    exitOnClick();
                    clearShowingAvailability();
                    clearShowingScheduled();
                }}
            />
            <div className={styles.dateAndListingAvailabilityContainer}>
                <div className={styles.pickADate}>
                    <h2 className={styles.timeSelectHeader}>Pick a Date</h2>
                    <div className={styles.dateSelect}>
                        {dates.map((date: DateObj, index: number) => (
                            <div
                                key={index}
                                className={
                                    date.day === scheduleData.date.day
                                        ? styles.dateItemContainerSelected
                                        : styles.dateItemContainer
                                }
                                onClick={() => (
                                    setScheduleData({ ...scheduleData, date, index }),
                                    setSelectedDay(index)
                                )}
                            >
                                <div className={styles.dateItemDayName}>{date.day}</div>
                                <div className={styles.dateItemDayNumber}>{date.date}</div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.listingAvailabilityHeaders}>
                    <p className={styles.listingAvailabilityHeader}>Listing Availability</p>
                </div>
                <div className={styles.listingAvailability}>
                    <div className={styles.listingAvailabilityTimes}>
                        {/* Print time labels on the left side of the calendar */}
                        {times.map((time: string, index: number) => (
                            // <div className={styles.calendarDiv}>
                            <div
                                key={time}
                                className={
                                    index === 0 || index % 4 === 0
                                        ? styles.listingAvailabilitySingleTimeBold
                                        : styles.listingAvailabilitySingleTimeLight
                                }
                            >
                                {index === 0 || index % 4 === 0
                                    ? time
                                    : time.substring(0, time.length - 2)}{' '}
                            </div>
                            //     {(index === 0 || index % 4 === 0) && (
                            //         <div className={styles.listingLine} key={index}></div>
                            //     )}
                            // </div>
                        ))}
                    </div>
                    {/* Print gray lines to the scrollable calendar */}
                    {[...Array(19).keys()].map((_: any, index: number) => (
                        <div
                            className={styles.listingLine}
                            key={index}
                            style={{ top: `${index * 70 + 10}px` }}
                        />
                    ))}

                    {unavailableDates[selectedDay] && unavailableDates[selectedDay].length
                        ? unavailableDates[selectedDay].map(
                              ({ start, end }: any, index: number) => {
                                  let endTime = { ...end };
                                  if (selectedDay === 0) {
                                      endTime = { ...endTime, hours: end.hours };
                                  }
                                  console.log('unavailableDates', unavailableDates);
                                  console.log(
                                      'selectedDay',
                                      selectedDay,
                                      'startTime',
                                      start,
                                      'endTime',
                                      endTime,
                                  );
                                  // ensure that unavailable time cannot exceed 21 hours
                                  if (end.hours >= 21) {
                                      endTime = { hours: 23, minutes: 0 };
                                  }

                                  return (
                                      <div
                                          key={index}
                                          className={styles.timeUnavailableBar}
                                          style={getUnavailableTimeStyle(start, endTime)}
                                      >
                                          <div className={styles.columnCenter}>
                                              <span className={styles.thisUnavailableText}>
                                                  Time Unavailable
                                              </span>
                                          </div>
                                      </div>
                                  );
                              },
                          )
                        : ''}

                    <div className={styles.thisShowingBar} style={getListingStyle()}>
                        <div className={styles.columnCenter}>
                            <span className={styles.thisShowingText}>This Showing</span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {getAgentListingInfo()}

                <div className={styles.rightSections}>
                    <h2 className={styles.pickATimeHeader}>Add Agent</h2>
                    {renderAgentSearch ? (
                        <>
                            <div className={styles.columnView}>
                                <SearchBar
                                    inputStyles={styles.addClientSearchBar}
                                    disabled={false}
                                    placeholderText={'Enter Name or Phone'}
                                    onSubmit={(searchString: string) => {
                                        setAgentSearchString(searchString);
                                    }}
                                />
                                {currentSearchedAgents?.length === 0 &&
                                    agentSearchString &&
                                    !loading && (
                                        <div className={styles.clientMessage}>No Agents Found</div>
                                    )}
                            </div>
                            <div className={styles.agentNamesBox}>
                                {currentSearchedAgents?.map((agent: any, index: number) => (
                                    <div
                                        key={index}
                                        className={styles.clientContainer}
                                        // style={{ top: index * 55 + 60 }}
                                        onClick={() => {
                                            setScheduleData({ ...scheduleData, agent });
                                            setRenderAgentSearch(false);
                                        }}
                                    >
                                        <span
                                            className={styles.clientName}
                                        >{`${agent.firstName} ${agent.lastName} - ${agent.markets[0].agentMlsId}`}</span>
                                    </div>
                                ))}
                            </div>
                        </>
                    ) : (
                        <>
                            {!scheduleData.agent.firstName && (
                                <>
                                    <Button
                                        buttonText={'Search Agent'}
                                        className={styles.addClientButton}
                                        onClick={() => setRenderAgentSearch(!renderAgentSearch)}
                                    />
                                </>
                            )}
                            {scheduleData.agent.firstName && (
                                <>
                                    <DetailsLabel
                                        value={`${scheduleData.agent.firstName}${' '}
                                                ${scheduleData.agent.lastName}
                                                ${' - '} ${
                                            scheduleData.agent.markets[0].agentMlsId
                                        }`}
                                    />
                                    <DetailsLabel
                                        value={formatPhoneNumberForDisplay(
                                            scheduleData.agent.phoneNumber,
                                        )}
                                    />
                                    <DetailsLabel
                                        value={formatEmailForDisplay(scheduleData.agent.email)}
                                    />
                                </>
                            )}
                        </>
                    )}
                </div>
                <div className={styles.rightSections}>
                    <h2 className={styles.pickATimeHeader}>Select Time</h2>
                    <div className={styles.cancelTextButton}>
                        NOTE: Displayed in Mountain Time &#8594; Take time zone into account when
                        scheduling (5am MST = 7am EST).
                    </div>
                    {availableShowingTimes.length === 0 ? (
                        <div>No Showings Available</div>
                    ) : (
                        <div className={styles.pickATime}>
                            <div
                                className={styles.pickATimeSelect}
                                onClick={() =>
                                    setRenderShowingTimeDropdown(!renderShowingTimeDropdown)
                                }
                            >
                                {scheduleData.time}
                            </div>
                            {renderShowingTimeDropdown && (
                                <div className={styles.pickATimeSelectDropdown}>
                                    {availableShowingTimesFiltered.map(
                                        (option: string, index: number) => (
                                            <div
                                                id="dropdown"
                                                data-value={option}
                                                key={option}
                                                className={styles.durationDropdownOption}
                                                onClick={() => {
                                                    setScheduleData({
                                                        ...scheduleData,
                                                        time: option,
                                                    });
                                                    setRenderShowingTimeDropdown(
                                                        !renderShowingTimeDropdown,
                                                    );
                                                }}
                                            >
                                                {option}
                                            </div>
                                        ),
                                    )}
                                </div>
                            )}

                            <p className={styles.pickATimeText}>for</p>
                            <div
                                className={styles.pickATimeSelect}
                                onClick={() => setRenderDurationDropdown(!renderDurationDropdown)}
                            >
                                {scheduleData.duration}
                            </div>

                            <div className={styles.durationDropdown}>
                                {renderDurationDropdown &&
                                    durationOptions.map((option: string) => {
                                        return (
                                            <div
                                                key={option}
                                                className={styles.durationDropdownOption}
                                                onClick={() => {
                                                    setScheduleData({
                                                        ...scheduleData,
                                                        duration: option,
                                                    });
                                                    setRenderDurationDropdown(
                                                        !renderDurationDropdown,
                                                    );
                                                }}
                                            >
                                                {option}
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    )}
                </div>
                <div className={styles.rightSections}>
                    <div className={styles.checkoutButtonContainer}>
                        {loading && (
                            <div className={styles.loadingContainer}>
                                <Loader
                                    icon={
                                        'https://showingly-image-assets.s3.amazonaws.com/Loader+Icon.gif'
                                    }
                                />
                            </div>
                        )}
                        {!loading &&
                            showingSuccessfullyCreated &&
                            (console.log('success'),
                            (
                                <div className={styles.showingSuccessMessage}>
                                    <DetailsLabel label={'The Showing has been Scheduled!'} />

                                    <DetailsLabel
                                        label={'Status: '}
                                        value={showingCreated.status}
                                    />
                                    {showingCreated.status === 'confirmed' && (
                                        <div className={styles.showingInfo}>
                                            <DetailsLabel
                                                label={'LockBox Type: '}
                                                value={
                                                    showingCreated.listing?.agentListing
                                                        ?.lockboxType
                                                }
                                            />
                                            <DetailsLabel
                                                label={'LockBox: '}
                                                value={
                                                    showingCreated.listing?.agentListing?.lockCombo
                                                }
                                            />
                                            <DetailsLabel
                                                label={'Showing Instructions: '}
                                                value={
                                                    showingCreated.listing?.agentListing
                                                        ?.showingInstructions
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                            ))}
                        {!loading &&
                            !showingSuccessfullyCreated &&
                            (console.log('showingSuccessfullyCreated', showingSuccessfullyCreated),
                            (
                                <>
                                    <Button
                                        buttonText={'Schedule'}
                                        className={
                                            canScheduleThisTimeSlot()
                                                ? styles.checkoutDoneButton
                                                : styles.grayedOutButton
                                        }
                                        disabled={!canScheduleThisTimeSlot()}
                                        onClick={() => {
                                            const { startTime, endTime } = getStartAndEndTime();
                                            onClickSchedule(startTime, endTime, scheduleData.agent);
                                        }}
                                    />
                                </>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ScheduleShowingModal;
