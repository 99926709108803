import { useEffect } from 'react';
import { DateTime } from 'luxon';
import { routerPush, routerRef } from './router';
export { routerRef, routerPush };

export const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

/**
 * Takes the phone number format stored in stitch and returns the number in
 * the format `+1 (012) 345-6789` or w/o international code if none is given.
 * @param {string} phoneNumber in the format `+10123456789` or w/o intl code
 * @param {boolean} includeDialingCode - Whether or not to add the country code
 * @returns {string}
 */
export function formatPhoneNumberForDisplay(phoneNumber: string, includeDialingCode = false) {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match && match.length > 4) {
        const intlCode = match[1] ? '+1 ' : '';
        return includeDialingCode
            ? [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
            : ['(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return phoneNumber;
}

export function formatEmailForDisplay(email: any) {
    var formattedEmail = email;
    if (email?.indexOf('+')) {
        const front = email.substr(0, email.indexOf('+'));
        const end = email.substr(email.indexOf('@'), email.length);
        if (front?.length && end?.length) {
            formattedEmail = front + end;
        }
    }
    return formattedEmail;
}

export function useOutsideClick(ref: any, doStuff: any) {
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                doStuff();
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
}

export function timeSince(date: any) {
    if (date) {
        let dateText;
        const timeDifferenceMinutes = (Date.now() - date.getTime()) / 60000;
        if (timeDifferenceMinutes) {
            if (timeDifferenceMinutes < 30 && timeDifferenceMinutes >= 0) {
                dateText = '15min';
            } else if (timeDifferenceMinutes < 45 && timeDifferenceMinutes >= 30) {
                dateText = '30min';
            } else if (timeDifferenceMinutes < 60 && timeDifferenceMinutes >= 45) {
                dateText = '45min';
            } else if (timeDifferenceMinutes < 120 && timeDifferenceMinutes >= 60) {
                dateText = '1hr';
            } else if (timeDifferenceMinutes < 240 && timeDifferenceMinutes >= 120) {
                dateText = '2hr';
            } else if (timeDifferenceMinutes < 360 && timeDifferenceMinutes >= 240) {
                dateText = '3hr';
            } else if (timeDifferenceMinutes < 1440 && timeDifferenceMinutes >= 360) {
                dateText = 'Today';
            } else if (timeDifferenceMinutes < 2880 && timeDifferenceMinutes >= 1440) {
                dateText = 'Yesterday';
            } else {
                dateText = DateTime.fromJSDate(date).toFormat('D');
            }
            return dateText;
        }
    }
    return 'No Session'; //Return no session if no last session is stored
}
