import React from 'react';
import { Col, Row } from 'react-grid-system';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/Button';
import styles from './index.module.css';
import { fetchMarketsRequested } from './../actions';
import { connect } from 'react-redux';
import { getAdminAccount } from '../../auth/selectors';

const mapStateToProps = (state: any) => ({
    account: getAdminAccount(state),
});

const connector = connect(mapStateToProps);

function Calendar({ account }: any) {
    console.log('account', account);
    // grab index of + or @ to get beginning of email.
    let n =
        account.email.indexOf('+') != -1 ? account.email.indexOf('+') : account.email.indexOf('@');
    console.log('n', n);
    // get usr for calendar
    const user = account.email.substring(0, n);

    console.log('user', user);

    const calLink = `https://calendar.google.com/calendar/embed?src=${user}%40getshowingly.com&ctz=America%2FDenver`;

    return (
        <div className={styles.root}>
            <iframe className={styles.iframe} src={calLink}></iframe>
        </div>
    );
}

export default connector(Calendar);
