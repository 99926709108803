import { STATUS } from '../../store/api/constants';

export enum DASH_ACTIONS {
    FetchAgents = '[Dash] Fetch Agents',
    FetchMarkets = '[Dash] Fetch Markets',
}

export const fetchAgentsRequested = (first: string, last: string, agentStatus: any) =>
    ({
        type: DASH_ACTIONS.FetchAgents,
        status: STATUS.Requested,
        first,
        last,
        agentStatus,
    } as const);

export const fetchAgentsFailed = (error: any) =>
    ({ type: DASH_ACTIONS.FetchAgents, status: STATUS.Failed, error } as const);

export const fetchAgentsSucceeded = (agents: any[]) =>
    ({ type: DASH_ACTIONS.FetchAgents, status: STATUS.Succeeded, agents } as const);

export type FetchAgentsAction =
    | ReturnType<typeof fetchAgentsRequested>
    | ReturnType<typeof fetchAgentsSucceeded>
    | ReturnType<typeof fetchAgentsFailed>;

// ---------------------------------------------------------------------------------------

export const fetchMarketsRequested = () =>
    ({
        type: DASH_ACTIONS.FetchMarkets,
        status: STATUS.Requested,
    } as const);

export const fetchMarketsFailed = (error: Error) =>
    ({ type: DASH_ACTIONS.FetchMarkets, status: STATUS.Failed, error } as const);

export const fetchMarketsSucceeded = (markets: any[]) =>
    ({ type: DASH_ACTIONS.FetchMarkets, status: STATUS.Succeeded, markets } as const);

export type FetchMarketsAction =
    | ReturnType<typeof fetchMarketsRequested>
    | ReturnType<typeof fetchMarketsFailed>
    | ReturnType<typeof fetchMarketsSucceeded>;

// ---------------------------------------------------------------------------------------

export type DashActions = FetchAgentsAction | FetchMarketsAction;
