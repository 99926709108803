import { STATUS } from '../../../../store/api/constants';

export enum AGENT_SEARCH_ACTIONS {
    search = '[Agent Search] MongoDB Query',
}

export function searchForAgentRequested(searchTerm: string) {
    return {
        type: AGENT_SEARCH_ACTIONS.search,
        status: STATUS.Requested,
        searchTerm,
    } as const;
}

export function searchForAgentSucceeded(agents: any[]) {
    return {
        type: AGENT_SEARCH_ACTIONS.search,
        status: STATUS.Succeeded,
        agents,
    } as const;
}

export function searchForAgentFailed(error: Error) {
    return {
        type: AGENT_SEARCH_ACTIONS.search,
        status: STATUS.Failed,
        error,
    } as const;
}

export type searchActions =
    | ReturnType<typeof searchForAgentRequested>
    | ReturnType<typeof searchForAgentSucceeded>
    | ReturnType<typeof searchForAgentFailed>;
