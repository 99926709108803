import { generate } from 'generate-password';
import {
    BSON,
    Stitch,
    StitchUser,
    UserPasswordAuthProviderClient,
    UserPasswordCredential,
} from 'mongodb-stitch-browser-sdk';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { getClient, getStitchUser, getStitchUserId } from '../../../redux';
import {
    AGENTS,
    BADGES,
    CONSUMERS,
    LISTINGS_COLLECTION,
    STATUS,
} from '../../../store/api/constants';
import {
    callStitchFunction,
    findRecord,
    findRecords,
    getDatabase,
    parseStitchServiceError,
    upsertAgentRecord,
    upsertBrokerageRecord,
} from '../../../store/api/sagas';
import { emailRegex } from '../../../utils';
import * as Actions from './actions';

function* fetchBrokerages(args: ReturnType<typeof Actions.fetchBrokeragesRequested>) {
    try {
        const client = Stitch.defaultAppClient;
        let brokerages = [];
        const marketName = 'Colorado (REColorado)';
        brokerages = yield call([client, 'callFunction'], 'textSearchBrokerage', [
            args.searchText,
            marketName,
        ]);
        if (brokerages) yield put(Actions.fetchBrokeragesSucceeded(brokerages));
        else throw new Error('Failed to fetch brokerages.');
    } catch (error) {
        yield put(Actions.fetchBrokeragesFailed(error));
    }
}

function* createBrokerage({ newBrokerage }: ReturnType<typeof Actions.createBrokerageRequested>) {
    try {
        const client: any = Stitch.defaultAppClient;

        const brokerageCreated = yield call(
            callStitchFunction,
            client,
            'addBrokerage',
            newBrokerage,
        );
        if (brokerageCreated) {
            yield put(Actions.createBrokerageSucceeded(brokerageCreated));
        } else {
            window.alert('Error');
        }
    } catch (error) {
        yield put(Actions.createBrokerageFailed(error));
        const mes = parseStitchServiceError(error);
        window.alert(mes);
    }
}

function* registerRealmUser(email: string, password?: string) {
    const client = Stitch.defaultAppClient;
    if (!emailRegex.test(email)) {
        throw Error('Email address is invalid');
    }
    if (password === undefined) password = generate({ length: 8, numbers: true });
    yield client.auth
        .getProviderClient(UserPasswordAuthProviderClient.factory)
        .registerWithEmail(email, password);
    yield client.auth.loginWithCredential(new UserPasswordCredential(email, password));
    // assert that auth.user is defined after login
    return {
        _id: (<StitchUser>client.auth.user).id,
        username: email,
        password: password,
    };
}

function* createDatabaseAccount(
    newUser: any,
    accountCreationFunction: string,
    accountCollection: string,
) {
    const accountId = yield call(
        [Stitch.defaultAppClient, 'callFunction'],
        accountCreationFunction,
        [newUser],
    );
    return yield call(getDatabase().collection(accountCollection).findOne, {
        _id: accountId,
    });
}

function* createUserAndAccount(
    action:
        | ReturnType<typeof Actions.createAgentUserRequested>
        | ReturnType<typeof Actions.createBrokerageUserRequested>,
) {
    try {
        const adminClient = Stitch.defaultAppClient;
        if (adminClient.auth.user?.id === undefined) {
            throw Error('Must be logged in to create an account');
        }

        const client = yield select(getClient);

        const newUser = yield call(registerRealmUser, action.newUser.email, action.newUser.phone);
        var newBrokerageUser = null;

        var account: any;
        switch (action.type) {
            case Actions.DM_ACTION.CreateAgentUser:
                account = yield call(
                    createDatabaseAccount,
                    action.newUser,
                    'agentAccountCreate',
                    'agents',
                );
                break;

            case Actions.DM_ACTION.CreateBrokerageUser:
                newBrokerageUser = {
                    stitchUserId: newUser._id,
                    brokerageId: action.newUser.brokerageId,
                    firstName: action.newUser.firstName,
                    lastName: action.newUser.lastName,
                    email: action.newUser.email,
                    phoneNumber: action.newUser.phone,
                    superAdmin: false,
                    dateCreated: new Date(),
                };

                account = yield call(
                    callStitchFunction,
                    client,
                    'brokerageUserCreate',
                    newBrokerageUser,
                );
                if (account) {
                    window.alert(
                        `Success. Please email the username: ${newUser.username} and password: ${newUser.password} to the client`,
                    );
                }
                break;
        }
        yield all([
            call(adminClient.auth.removeUserWithId, newUser._id),
            call(adminClient.auth.switchToUserWithId, adminClient.auth.user.id),
        ]);
        yield put(Actions.createUserSucceeded(action.type, newBrokerageUser ?? newUser, account));
    } catch (error) {
        yield put(Actions.createUserFailed(action.type, error));
    }
}

export function* addAgentContent({
    newContent,
}: ReturnType<typeof Actions.addAgentContentRequested>) {
    try {
        const client = yield select(getClient);
        const contentAdded = yield call(callStitchFunction, client, 'addAgentContent', newContent);
        if (contentAdded) {
            yield put(Actions.addAgentContentSucceeded());
        } else {
            yield put(Actions.addAgentContentFailed(null));
        }
    } catch (error) {
        yield put(Actions.addAgentContentFailed(error));
    }
}

export function* fetchBadges({ badgeType }: ReturnType<typeof Actions.fetchBadgesRequested>) {
    try {
        const badges = yield call(findRecords, BADGES, {
            _id: { $exists: true },
            // only match for badgeType if the parameter exists
            type: badgeType ?? {
                $ne: 'collection',
            },
        });
        if (badges) {
            yield put(Actions.fetchBadgesSucceeded(badges));
        }
    } catch (error) {
        yield put(Actions.fetchBadgesFailed(error));
    }
}

export function* updateAgent({
    agentId,
    updatedAgentFields,
}: ReturnType<typeof Actions.updateAgentRequested>) {
    try {
        const client = yield select(getClient);

        const updated = yield call(
            callStitchFunction,
            client,
            'updateDocumentAsAdmin',
            AGENTS,
            agentId,
            updatedAgentFields,
        );

        if (updated) {
            Actions.updateAgentSucceeded();
        }
    } catch (error) {
        yield put(Actions.updateAgentFailed(error));
    }
}

export function* searchListings({
    text,
    singleListingId,
}: ReturnType<typeof Actions.searchListingsRequested>) {
    try {
        const client = yield select(getClient);
        if (singleListingId) {
            const listing = yield call(findRecord, LISTINGS_COLLECTION, {
                listingId: singleListingId,
            });
            yield put(Actions.searchListingsSucceeded(listing));
        } else {
            const listings = yield call(
                callStitchFunction,
                client,
                'textSearchListings',
                text,
                true,
            );
            yield put(Actions.searchListingsSucceeded(listings));
        }
    } catch (error) {}
}

export function* fetchClients({ agentId }: ReturnType<typeof Actions.fetchAgentClientsRequested>) {
    try {
        const clients = yield call(findRecords, CONSUMERS, {
            agentId,
        });

        if (clients) {
            yield put(Actions.fetchAgentClientsSucceeded(clients));
        }
    } catch (error) {
        console.log('no clients or clients not working');
    }
}

export function* scheduleShowing({
    showingData,
    agent,
}: ReturnType<typeof Actions.scheduleShowingRequested>) {
    try {
        const client = yield select(getClient);

        console.log('showingData', showingData);
        console.log('agent', agent);

        const { dayOffset = null, time = '', duration = 60, utcOffset } = showingData;

        var today = new Date();
        // get correct day of week
        today.setDate(today.getDate() + parseInt(dayOffset));
        const year = today.getFullYear();
        const month = today.getMonth();
        const day = today.getDate();
        const hour = time.substring(0, time.length - 3);
        const minute = time.substring(time.length - 2, time.length);

        const start = new Date(year, month, day, hour, minute);
        const end = new Date(start.getTime() + duration * 60000);

        const showing = yield call(
            callStitchFunction,
            client,
            'createShowing',
            'agent',
            showingData.listingId, // function will convert to objectId
            start,
            end,
            showingData.clientId && showingData.clientId !== 'starter'
                ? showingData.clientId
                : null,
            utcOffset || 7,
            null,
            agent.stitchUserId,
        );

        console.log('showing', showing);
    } catch (error) {
        console.log('schedule didnt work');
    }
}

export function* sagaDM() {
    yield all([
        takeLatest(
            (action: any) =>
                action.status === STATUS.Requested &&
                action.type === Actions.DM_ACTION.FetchBrokerages,
            fetchBrokerages,
        ),
        takeLatest(
            (action: any) =>
                action.status === STATUS.Requested && action.type === Actions.DM_ACTION.FetchBadges,
            fetchBadges,
        ),
        takeLatest(
            (action: any) =>
                action.status === STATUS.Requested && action.type === Actions.DM_ACTION.UpdateAgent,
            updateAgent,
        ),
        takeLatest(
            (action: any) =>
                action.status === STATUS.Requested &&
                action.type === Actions.DM_ACTION.SearchListings,
            searchListings,
        ),
        takeLatest(
            (action: any) =>
                action.status === STATUS.Requested &&
                action.type === Actions.DM_ACTION.ScheduleShowing,
            scheduleShowing,
        ),
        takeLatest(
            (action: any) =>
                action.status === STATUS.Requested &&
                action.type === Actions.DM_ACTION.CreateBrokerage,
            createBrokerage,
        ),
        takeLatest(
            (action: any) =>
                action.status === STATUS.Requested &&
                action.type === Actions.DM_ACTION.FetchAgentClients,
            fetchClients,
        ),
        takeLatest(
            (action: any) =>
                action.status === STATUS.Requested &&
                action.type === Actions.DM_ACTION.AddAgentContent,
            addAgentContent,
        ),
        takeLatest(
            (action: any) =>
                action.status === STATUS.Requested &&
                (action.type === Actions.DM_ACTION.CreateBrokerageUser ||
                    action.type === Actions.DM_ACTION.CreateAgentUser),
            createUserAndAccount,
        ),
    ]);
}
