import React, { useEffect, useState } from 'react';
import styles from './index.module.css';
import GeneralInfo from '../Common/GeneralInfo';
import { DateTime } from 'luxon';
import { timeSince } from '../../../../utils/';
import { connect } from 'react-redux';
import {
    getLoading,
    getSelectedBrokerage,
    getBrokerageRoster,
    getBrokerageAdmins,
} from '../selectors';
import { updateSelectedBrokerageRequested } from '../actions';
import UserCard from '../Common/UserCard';
import { Loader, SearchBar } from 'web-lib';
import { Assets } from 'asset-lib/src/index';
import { TableHeader, PersonCard } from '../../../../components';
import { BSON } from 'mongodb-stitch-browser-sdk';

const mapDispatchToProps = {
    updateSelectedBrokerageRequested: updateSelectedBrokerageRequested,
};
const mapStateToProps = (state: any) => ({
    loading: getLoading(state),
    brokerage: getSelectedBrokerage(state),
    agents: getBrokerageRoster(state),
    admins: getBrokerageAdmins(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type BrokerageViewerProps = {
    loading: boolean;
    brokerage: any;
    agents: any[];
    admins: any[];
    listings?: any;
    updateSelectedBrokerageRequested: Function;
};

export const BrokerageViewer = ({
    loading,
    brokerage,
    agents,
    admins,
    listings,
    updateSelectedBrokerageRequested,
}: BrokerageViewerProps) => {
    const [agentsToMap, setAgentsToMap] = useState<any[]>(agents);
    const brokerageStatuses = ['unverified', 'verified', 'suspended', 'denied'];

    const saveBrokerage = (newStatus: string, superAdmin: any, allBrokerageAdmins: any[]) => {
        var adminsToUpdate = [];
        const superAdminToSet = superAdmin?._id ? new BSON.ObjectID(superAdmin._id) : null;

        // remove super admin from rest of admins to update separately
        if (allBrokerageAdmins && superAdmin) {
            allBrokerageAdmins.forEach((admin: any) => {
                if (superAdmin._id.toString() != admin._id.toString()) {
                    adminsToUpdate.push(admin._id);
                }
            });
        }
        updateSelectedBrokerageRequested(brokerage._id, newStatus, superAdminToSet, adminsToUpdate);
    };
    const submitHandler = (searchText: any) => {
        //If the user inputs something, let's search...
        if (agents && searchText !== '') {
            setAgentsToMap(
                agents.filter((agent: any) =>
                    (agent.firstName + ' ' + agent.lastName)
                        .toLowerCase()
                        .includes(searchText.toLowerCase()),
                ),
            );
        }
        //Other wise reset the agents to the initial array
        else if (searchText === '') {
            setAgentsToMap(agents);
        }
    };
    useEffect(() => {
        if (!agentsToMap.length) {
            setAgentsToMap(agents);
        }
    }, [agents]);

    return (
        <div className={styles.root}>
            <div className={styles.row}>
                <div className={styles.generalInfoDiv}>
                    <GeneralInfo
                        name={brokerage.name}
                        id={brokerage.brokerageId}
                        market={brokerage.market}
                        mlsName={brokerage.mlsName}
                        status={
                            brokerage.adminVerificationStatus
                                ? brokerage.adminVerificationStatus
                                : ''
                        }
                        hasSubscription={
                            brokerage?.subscription?.active
                                ? brokerage?.subscription?.active
                                : false
                        }
                        agentCount={brokerage.agentCount ? brokerage.agentCount : 0}
                        isEditable={true}
                        onClickSave={saveBrokerage}
                        possibleStatuses={brokerageStatuses}
                        admins={admins}
                    />
                </div>
                <div className={styles.adminDiv}>
                    <div className={styles.sectionText}>Brokerage Admins:</div>
                    <div className={styles.line}></div>

                    {loading && (
                        <div className={styles.loader}>
                            <Loader />
                        </div>
                    )}
                    {admins &&
                        admins.length > 0 &&
                        !loading &&
                        admins.map((admin: any) => {
                            return (
                                <>
                                    <UserCard
                                        name={`${admin.firstName} ${admin.lastName} `}
                                        phoneNumber={admin.phoneNumber}
                                        email={admin.email}
                                        status={admin.status ? admin.status : ''}
                                        isLicensedAgent={
                                            admin.agentObjectId || admin.userObjectId ? true : false
                                        }
                                        isSuperAdmin={
                                            admin.isSuperAdmin ? admin.isSuperAdmin : false
                                        }
                                        lastSession={
                                            admin.lastSession ? timeSince(admin.lastSession) : null
                                        }
                                        signUpDate={
                                            admin.signUpDate ? timeSince(admin.signUpDate) : null
                                        }
                                    />
                                    <div className={styles.line}></div>
                                </>
                            );
                        })}
                    {admins.length === 0 && !loading && (
                        <div className={styles.noBrokerages}>No Brokerage Admins</div>
                    )}
                </div>
            </div>
            <div className={styles.agentDirectory}>
                <SearchBar
                    inputStyles={styles.searchBarInputStyles}
                    onSubmit={(newText: any) => submitHandler(newText)}
                    disabled={false}
                    placeholderText={'Search by Name'}
                />
                <TableHeader type={'agent'} />
                <div className={styles.agentDirectoryScroll}>
                    {agentsToMap &&
                        agentsToMap.length &&
                        agentsToMap.map((agent: any, index: number) => {
                            function onClickAgent() {
                                window.alert('🤦🏽‍♀🧑🏽‍💻 Not Available Yet 🧑🏽‍💻🤦🏽‍♀');
                            }
                            return (
                                <PersonCard
                                    name={`${agent.firstName} ${agent.lastName}`}
                                    firstElement={agent?.phoneNumber}
                                    secondElement={agent.email}
                                    thirdElement={
                                        agent.markets && agent.markets.length && agent.markets[0]
                                            ? agent.markets[0].agentMlsId
                                            : ''
                                    }
                                    status={agent.status}
                                    type="agent"
                                    onClickViewProfile={onClickAgent}
                                />
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default connector(BrokerageViewer);
