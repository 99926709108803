import { ErrorObject, serializeError } from 'serialize-error';
import { STATUS } from '../../store/api/constants';
import { AuthAction, AUTH_ACTION } from './actions';

export type AuthState = {
    account: any;
    loading: boolean;
    error: ErrorObject | null;
    isAuthenticated: boolean;
    client: any;
    stitchUser: any;
    stitchUserId: any;
    initialization: boolean;
};

export const initialState: AuthState = {
    account: null,
    loading: false,
    error: null,
    isAuthenticated: false,
    initialization: false,
    client: null,
    stitchUser: null,
    stitchUserId: null,
};

export function reducerAuth(state = initialState, action: AuthAction): AuthState {
    if (!Object.values(AUTH_ACTION).includes(action.type)) {
        return state;
    }
    switch (action.status) {
        case STATUS.Succeeded:
            switch (action.type) {
                case AUTH_ACTION.Login:
                    return {
                        ...state,
                        account: action.account,
                        loading: false,
                        isAuthenticated: true,
                    };
                case AUTH_ACTION.Initialization: {
                    return {
                        ...state,
                        isAuthenticated: action.isAuthenticated,
                    };
                }
                case AUTH_ACTION.StitchUserActions:
                    return {
                        ...state,
                        client: action.client,
                        stitchUser: action.stitchUser,
                        stitchUserId: action.stitchUserId,
                    };
                case AUTH_ACTION.Logout:
                    return {
                        ...state,
                        loading: false,
                    };
                default:
                    return state;
            }
        case STATUS.Requested:
            switch (action.type) {
                case AUTH_ACTION.Initialization: {
                    return {
                        ...state,
                        initialization: action.initialization,
                    };
                }
                default:
                    return {
                        ...state,
                        loading: true,
                    };
            }
        case STATUS.Failed:
            return {
                ...state,
                loading: false,
                error: serializeError(action.error),
            };
        default:
            return state;
    }
}
