import { ErrorObject, serializeError } from 'serialize-error';
import { STATUS } from '../../../store/api/constants';
import { SEARCH_ACTION } from './actions';

export const initialState = {
    loading: false,
    // searching
    agents: [],
    brokerages: [],
    consumers: [],
    listings: [],
    // viewer
    selectedViewingBrokerage: null,
    brokerageRoster: [],
    brokerageAdmins: [],
    selectedViewingAgent: null,
    // scheduling
    selectedListing: null,
    selectedListingMarketData: null,
    unavailableDates: [],
    showingSuccessfullyCreated: false,
    showingCreated: null,

    error: null,
};
export type Searches = typeof initialState;

export function reducerSearch(state = initialState, action: any) {
    const { type, status } = action;
    switch (type) {
        case SEARCH_ACTION.TextSearchAgents: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                    };
                }
                case STATUS.Succeeded: {
                    const { agents } = action;
                    return {
                        ...state,
                        agents,
                        loading: false,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        error,
                        loading: false,
                    };
                }
                default:
                    return state;
            }
        }
        case SEARCH_ACTION.TextSearchListings: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                    };
                }
                case STATUS.Succeeded: {
                    const { listings } = action;
                    return {
                        ...state,
                        listings,
                        loading: false,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        error,
                        loading: false,
                    };
                }
                default:
                    return state;
            }
        }
        case SEARCH_ACTION.TextSearchBrokerages: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                    };
                }
                case STATUS.Succeeded: {
                    const { brokerages } = action;
                    return {
                        ...state,
                        brokerages,
                        loading: false,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        error,
                        loading: false,
                    };
                }
                default:
                    return state;
            }
        }
        case SEARCH_ACTION.FetchShowingAvailability: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loading: true,
                    };
                }
                case STATUS.Succeeded: {
                    const { unavailableDates } = action;
                    return {
                        ...state,
                        unavailableDates,
                        loading: false,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        error,
                        loading: false,
                    };
                }
                default:
                    return state;
            }
        }
        case SEARCH_ACTION.ScheduleShowing: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loading: true,
                    };
                }
                case STATUS.Succeeded: {
                    const { showingCreated } = action;
                    return {
                        ...state,
                        showingCreated,
                        loading: false,
                        showingSuccessfullyCreated: true,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        error,
                        loading: false,
                    };
                }
                default:
                    return state;
            }
        }
        case SEARCH_ACTION.ClearShowingAvailability: {
            return {
                ...state,
                unavailableDates: [],
            };
        }

        case SEARCH_ACTION.ClearShowingScheduled: {
            return {
                ...state,
                showingSuccessfullyCreated: false,
                showingCreated: null,
            };
        }

        case SEARCH_ACTION.SetSelectedBrokerage: {
            const { brokerage } = action;
            return {
                ...state,
                selectedViewingBrokerage: brokerage,
            };
        }
        case SEARCH_ACTION.FetchBrokerageRoster: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loading: true,
                    };
                }
                case STATUS.Succeeded: {
                    const { roster } = action;
                    return {
                        ...state,
                        brokerageRoster: roster,
                        loading: false,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        error,
                        loading: false,
                    };
                }
                default:
                    return state;
            }
        }
        case SEARCH_ACTION.FetchBrokerageAdmins: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loading: true,
                    };
                }
                case STATUS.Succeeded: {
                    const { admins } = action;
                    return {
                        ...state,
                        brokerageAdmins: admins,
                        loading: false,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        error,
                        loading: false,
                    };
                }
                default:
                    return state;
            }
        }
        case SEARCH_ACTION.UpdateSelectedBrokerage: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loading: true,
                    };
                }
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        loading: false,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        error,
                        loading: false,
                    };
                }
                default:
                    return state;
            }
        }

        case SEARCH_ACTION.SetAgentSelected: {
            const { agent } = action;
            return {
                ...state,
                selectedViewingAgent: agent,
            };
        }

        default:
            return state;
    }
}
