import { combineReducers } from 'redux';
import { reducerAuth as auth } from '../redux/auth';
import agentSearch from '../domains/dash/AllSearches/AgentSearch/reducers';
import { reducerSearch } from '../domains/dash/AllSearches/reducers';
import { reducerDM } from '../domains/dash/DataManipulation/reducer';
import { reducerDash } from '../domains/dash/reducer';

// DEVELOPER NOTE: Please keep the reducers in alphabetical order.

export const RootReducer = combineReducers({
    agentSearch,
    searches: reducerSearch,
    auth,
    dash: reducerDash,
    dataManipulation: reducerDM,
});

export type WebAdminState = ReturnType<typeof RootReducer>;
