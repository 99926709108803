import { all, call } from 'redux-saga/effects';
import { sagaAuth } from '../domains/auth/sagas';
import { sagaDM } from '../domains/dash/DataManipulation/sagas';
import { sagaSearch } from '../domains/dash/AllSearches/sagas';
import { sagaDash } from '../domains/dash/sagas';
import { init } from '../init/saga';
import searchSaga from '../domains/dash/AllSearches/AgentSearch/sagas';

export function* rootSaga() {
    yield all([
        call(init),
        call(sagaAuth),
        call(sagaDM),
        call(sagaDash),
        call(searchSaga),
        call(sagaSearch),
    ]);
}
