import { Stitch } from 'mongodb-stitch-browser-sdk';
import { call, put } from 'redux-saga/effects';
//import { loginSucceeded } from 'web-lib/dist/redux/auth';
import { fetchAdminAccount } from '../store/api/sagas';

export function* init() {
    if (process.env.REACT_APP_STITCH_APP_ID === undefined) {
        throw Error('Stitch App ID undefined.');
    }
    yield call(Stitch.initializeDefaultAppClient, process.env.REACT_APP_STITCH_APP_ID);
    const client = Stitch.defaultAppClient;
    const adminAccount = yield call(fetchAdminAccount);
    console.log('user', client.auth.user);
    console.log('acct', adminAccount);
    if (client.auth.isLoggedIn && adminAccount) {
        //yield put(loginSucceeded(adminAccount));
    }
}
