import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import AuthenticatedApp from './authenticated-app';
import { WebAdminState } from './store/reducer';
import UnauthenticatedApp from './unauthenticated-app';
import { getIsUserAuthenticated } from './domains/auth/selectors';

const mapStateToProps = (state: WebAdminState) => ({
    authenticated: getIsUserAuthenticated(state),
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

function App({ authenticated }: ReduxProps) {
    if (authenticated && authenticated === true) {
        return <AuthenticatedApp />;
    } else {
        return <UnauthenticatedApp />;
    }
}

export const ConnectedApp = connector(App);
