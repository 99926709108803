import { createSelector } from 'reselect';
import { AuthState } from './reducer';

export const getAuthState = (state: { auth: AuthState }) => state.auth;

export const isAuthLoading = createSelector(getAuthState, (auth) => auth.loading);

export const getAccount = createSelector(getAuthState, (auth) => auth.account);

export const isAuthenticated = createSelector(getAccount, (account) => account !== null);

export const getAuthError = createSelector(getAuthState, (auth) => auth.error);

export const getClient = createSelector(getAuthState, (auth) => auth.client);

export const getStitchUser = createSelector(getAuthState, (auth) => auth.stitchUser);

export const getStitchUserId = createSelector(getAuthState, (auth) => auth.stitchUserId);
