import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './index.module.css';

const DocumentCards = ({ file }: any) => {
    console.log(file);
    const history = useHistory();
    return (
        <div
            className={styles.root}
            onClick={() => {
                history.push(`/supportdocs/${file.id}`);
                window.scrollTo(0, 0);
            }}
        >
            <div className={styles.title}>{file?.title}</div>
        </div>
    );
};

export default DocumentCards;
