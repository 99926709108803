import React, { useState } from 'react';
import styles from '../index.module.css';
import { Container, Row, Col } from 'react-grid-system';
import { launchAllCharts, launchCOCharts, launchFLCharts } from '../mongoCharts';
import Dropdown from '../../Dropdown';
import classnames from 'classnames';

//let debug = true;
function LaunchDash() {
    const marketOptions = ['Both', 'Colorado (REColorado)', 'Florida'];
    const [market, setMarket] = useState(marketOptions[0]);

    // chart specifications
    const maxDataAge = '600';
    const options = `&theme=dark&attribution=false&autoRefresh=true&maxDataAge=${maxDataAge}&`;
    var charts;

    switch (market) {
        case 'Both':
            charts = launchAllCharts;
            break;
        case 'Colorado (REColorado)':
            charts = launchCOCharts;
            break;
        case 'Florida':
            charts = launchFLCharts;
            break;
    }

    //styles
    const numberStyle = classnames(styles.iframeNumber, styles.dark);
    const doubleNum = classnames(styles.iframeDoubleNumber, styles.dark);
    const chartStyle = classnames(styles.iframeChart, styles.dark);

    return (
        //console.log('market', market),
        <div className={styles.chartContainer}>
            <div className={styles.title}>
                <div className={styles.label}>
                    <div>Market:</div>
                    <Dropdown
                        handleClick={setMarket}
                        options={marketOptions}
                        className={styles.dropdown}
                    />
                </div>
            </div>

            <div className={styles.row}>
                <div className={styles.row2}>
                    {charts.percentage ? (
                        <iframe
                            title="chart"
                            className={numberStyle}
                            src={`${charts.percentage}${options}`}
                        ></iframe>
                    ) : (
                        <div></div>
                    )}
                    <iframe
                        title="chart"
                        className={numberStyle}
                        src={`${charts.connectedListings}${options}`}
                    ></iframe>
                    <iframe
                        title="chart"
                        className={numberStyle}
                        src={`${charts.sessions}${options}`}
                    ></iframe>
                    <iframe
                        title="chart"
                        className={doubleNum}
                        src={`${charts.agentStatuses}${options}`}
                    ></iframe>
                </div>
                <div className={styles.row2}>
                    <iframe
                        title="chart"
                        className={chartStyle}
                        src={`${charts.agentsOverTime}${options}`}
                    ></iframe>
                    <iframe
                        title="chart"
                        className={chartStyle}
                        src={`${charts.completedShowings}${options}`}
                    ></iframe>
                    <iframe
                        title="chart"
                        className={chartStyle}
                        src={`${charts.listingViews}${options}`}
                    ></iframe>
                </div>
            </div>
        </div>
    );
}
export default LaunchDash;
