import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getMarkets } from '../../../domains/dash/selectors';
import { fetchMarketsRequested } from '../../../domains/dash/actions';
import { useHistory } from 'react-router-dom';
import styles from './index.module.css';
import { Button, ButtonTabBar, SupportDash } from '../../../components';
import SupportDocs from './SupportDocs';
import CallLogs from './CallLogs';
const mapStateToProps = (state: any) => ({
    markets: getMarkets(state),
});

const mapDispatchToProps = {
    fetchMarketsRequested: fetchMarketsRequested,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

function Support({ markets }: any) {
    let history = useHistory();
    function handleClick(path: string) {
        console.log('path', path);
        history.push(path);
    }
    const buttonOptions: { name: string; page: string }[] = [
        { name: 'Support Dashboard', page: 'supportDash' },
        { name: 'Call Logs', page: 'callLogs' },
        { name: 'Documentation', page: 'documentation' },
    ];
    const [page, setPage] = useState<string>('supportDash');

    return (
        <div className={styles.root}>
            <ButtonTabBar buttonNames={buttonOptions} setPage={setPage} currPage={page} />
            <div className={styles.section}>
                {
                    {
                        supportDash: <SupportDash markets={markets} />,
                        callLogs: <CallLogs />,
                        documentation: <SupportDocs />,
                    }[page]
                }
            </div>
        </div>
    );
}

export default connector(Support);
