const baseURL = 'https://charts.mongodb.com/charts-showingly-development-kcldi/embed/charts?id=';

export const chartSources = {
    // NUMBER CHARTS
    // GeneralDash
    totalAgents: `${baseURL}997b5126-e17f-43de-8740-28122358cc4c`,
    totalConsumer: `${baseURL}f0f45947-636c-4cfc-90c7-7860417de397`,
    upcomingShowings: `${baseURL}c8f5e971-f737-4844-9a16-a84506fadccf`,
    completedShowings: `${baseURL}85d5dcc6-c067-4cd3-9314-bb8cb379c16e`,
    connectedListings: `${baseURL}d4b4ca06-5ffc-4a80-b74a-e17494f6cf28`,
    OnlySSO: `${baseURL}c3efbd8b-5662-4584-b6ef-73c73a8a7129`,
    activeLast48: `${baseURL}c6a0d40e-8ea6-4a58-bc60-6e70978a28f8`,

    // GRAPHS
    // General Dash
    agentsEachDay: `${baseURL}9ae93722-9bf1-479f-8d64-145922a8f282`,
    agentsOverTime: `${baseURL}332ec7a7-6f4b-419f-be15-408739f34887`,
    completedShowingsGraph: `${baseURL}e7e24318-8ff2-4966-b671-edc0c7b92fc2`,
    completedDelegationsGraph: `${baseURL}78f96230-a0c9-4649-92bd-1dbde91ff0d9`,
    consumersEachDay: `${baseURL}4b380c5d-246e-4119-bbed-560e43becfe8`,
    consumersOverTime: `${baseURL}431d9a31-8d1f-432c-9996-a59e580212f8`,
    leadsPurchased: `${baseURL}f12807b3-ef1e-423b-abdc-d2140ca9e164`,

    // TABLES
    // General Dash
    agentsSubsMarket: `${baseURL}f3effa80-c08f-4f32-a8b6-cc30d9100437`,
    connectedListingsMarket: `${baseURL}b3205ae8-93e2-4c32-843f-5c62062c9307`,
    listingViews: `${baseURL}04beb5b4-2d9a-484e-a136-495b8b6b1149`,
    delegationsLeadsMarket: `${baseURL}fa413afa-d5fa-49ad-b210-4b70fbf2302f`,
    agentsPerMonth: `${baseURL}e9dac89b-0289-4cc0-b6f4-f6f6da6981be`,
    consumersPerMonth: `${baseURL}b7d5bd01-c223-49aa-bf10-98652f460675`,
};

export const supportCharts = {
    recentSignups: `${baseURL}eb9a3601-12b5-453f-bb0d-1e3a772ba94f`,
    connectedListingsTable: `${baseURL}07512034-4099-4a00-beef-abe506fb79df`,
    upcomingShowingsTable: `${baseURL}7a4836ce-0132-467e-a858-ee348d2989c8`,
    allDelegations: `${baseURL}3c8a6ac8-c155-4b60-96bc-f3d5f0d02ccc`,
};

export const launchAllCharts = {
    type: 'Both',
    agentsOverTime: `${baseURL}c08ee8bd-1f0c-48b5-82e8-c0a66632e445`,
    agentStatuses: `${baseURL}eb7fc7e6-3e49-4dbf-bc65-78903b9613ab`,
    connectedListings: `${baseURL}dbfff995-d849-447f-9997-b561ad219cd3`,
    listingViews: `${baseURL}e59b531c-df87-4d58-9917-8d17daab195c`,
    completedShowings: `${baseURL}8d4fd3d6-ce06-4fa4-a456-9ca1b845b87c`,
    sessions: `${baseURL}3a3edbc4-92c2-4cb7-855a-597caf6d98be`,
};
export const launchCOCharts = {
    type: 'Colorado',
    agentsOverTime: `${baseURL}d4b7ee0f-e80e-4979-bef0-79213ff79535`,
    agentStatuses: `${baseURL}0b2f5008-0203-4a63-b068-7e54142e5742`,
    connectedListings: `${baseURL}a37d5e2e-da4a-4f54-8789-e73afd975ec6`,
    listingViews: `${baseURL}d779f4d4-956e-4258-8038-306319625592`,
    completedShowings: `${baseURL}14c6da55-a510-43a2-a230-62433f044ee6`,
    percentage: `${baseURL}b4c9b2d9-2f67-44e8-8d68-11696f0e52fa`,
    sessions: `${baseURL}97ba7e70-1b00-4223-a050-c7dba3f02d77`,
};
export const launchFLCharts = {
    type: 'Florida',
    agentsOverTime: `${baseURL}d7766592-ae93-448b-bffb-71a7a206b5a8`,
    agentStatuses: `${baseURL}57cc203c-5ffc-4656-96f7-e0c2c26ce098`,
    connectedListings: `${baseURL}c2dbafbf-fa59-475b-9fa2-39c3fc026b9f`,
    listingViews: `${baseURL}94d981eb-6c52-4088-904a-17f6b030b998`,
    completedShowings: `${baseURL}cb4580d9-9f68-40cf-accc-3ea78159a5a5`,
    percentage: `${baseURL}7d801b48-63fc-4616-8ba2-32fcf37031ad`,
    sessions: `${baseURL}f716ed6a-5138-4ec2-a1f0-71910a1a580d`,
};
