import React from 'react';
import { Col, Row } from 'react-grid-system';
import { useHistory } from 'react-router-dom';
import styles from './index.module.css';

function CallLogs() {
    const link =
        'https://docs.google.com/spreadsheets/d/15ff3mLv51z3FB5rj87t9Ne0kH-ynRF_GUVJYWq0P5BA/edit?usp=sharing';
    return (
        <div className={styles.root}>
            <iframe className={styles.iframe} src={link}></iframe>
        </div>
    );
}

export default CallLogs;
