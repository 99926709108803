import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { BSON } from 'mongodb-stitch-browser-sdk';
import { Button, PersonCard, ListingCard, TableHeader } from '../../../components';
import styles from './index.module.css';
import { ButtonTabBar, ScheduleShowingModal } from '../../../components';
import { Loader, SearchBar, PropertyListingCard } from 'web-lib';
import { useHistory } from 'react-router-dom';

import {
    searchAgentsRequested,
    searchListingsRequested,
    searchBrokeragesRequested,
    fetchShowingAvailabilityRequested,
    scheduleShowingRequested,
    clearShowingAvailability,
    clearShowingScheduled,
    setSelectedBrokerage,
    fetchBrokerageRosterRequested,
    fetchBrokerageAdminsRequested,
    setAgentSelected,
} from './actions';
import {
    getAgents,
    getBrokerages,
    getListings,
    getLoading,
    getUnavailableDates,
    getScheduledShowing,
    getShowingSuccessfullyCreated,
    getSelectedAgent,
    getAgentLoading,
} from './selectors';
import { getMarkets } from '../selectors';
import { Assets } from 'asset-lib/src/index';
import { formatAvailability, arrangeDates } from '../../../utils/schedulingHelpers';

const mapDispatchToProps = {
    searchAgentsRequested: searchAgentsRequested,
    searchListingsRequested: searchListingsRequested,
    searchBrokeragesRequested: searchBrokeragesRequested,
    fetchShowingAvailabilityRequested: fetchShowingAvailabilityRequested,
    scheduleShowingRequested: scheduleShowingRequested,
    clearShowingAvailability: clearShowingAvailability,
    clearShowingScheduled: clearShowingScheduled,
    setSelectedBrokerage: setSelectedBrokerage,
    fetchBrokerageRosterRequested: fetchBrokerageRosterRequested,
    fetchBrokerageAdminsRequested: fetchBrokerageAdminsRequested,
    setAgentSelected: setAgentSelected,
};
const mapStateToProps = (state: any) => ({
    markets: getMarkets(state),
    agents: getAgents(state),
    brokerages: getBrokerages(state),
    listings: getListings(state),
    loading: getLoading(state),
    unavailableDates: getUnavailableDates(state),
    scheduledShowing: getScheduledShowing(state),
    showingSuccessfullyCreated: getShowingSuccessfullyCreated(state),
    agent: getSelectedAgent(state),
    agentLoading: getAgentLoading(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

const AllSearches = ({
    agents,
    brokerages,
    listings,
    markets,
    loading,
    unavailableDates,
    scheduledShowing,
    showingSuccessfullyCreated,
    searchAgentsRequested,
    searchListingsRequested,
    searchBrokeragesRequested,
    fetchShowingAvailabilityRequested,
    scheduleShowingRequested,
    clearShowingAvailability,
    clearShowingScheduled,
    setSelectedBrokerage,
    fetchBrokerageRosterRequested,
    fetchBrokerageAdminsRequested,
    setAgentSelected,
}: any) => {
    const buttonOptions: { name: string; page: string }[] = [
        { name: 'Agent Search', page: 'agents' },
        { name: 'Brokerage Search', page: 'brokerages' },
        // { name: 'Consumer Search', page: 'consumers' },
        { name: 'Listing Search', page: 'listings' },
    ];

    const history = useHistory();

    const [page, setPage] = useState<string>('agents');
    const [searchText, setSearchText] = useState<string>('');
    const [disabled, setDisabled] = useState<boolean>();
    const [renderModal, setRenderModal] = useState<boolean>(false);
    const [selectedListing, setSelectedListing] = useState<any>();
    const [selectedMarketString, setSelectedMarketString] = useState<string>();
    const [selectedMarket, setSelectedMarket] = useState<any>();
    const [orderedDates, setOrderedDates] = useState<any[]>([]);

    useEffect(() => {
        setSearchText('');
    }, [window.location, page]);

    //This disabels the search bar if a market isn't selected.
    useEffect(() => {
        if (!selectedMarket && !selectedMarketString) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [selectedMarketString, selectedMarket]);
    useEffect(() => {
        if (selectedMarketString && selectedMarketString !== 'None')
            setSelectedMarket(JSON.parse(selectedMarketString));
    }, [selectedMarketString]);

    // get availibility once listing is selected
    useEffect(() => {
        if (selectedListing) {
            fetchShowingAvailabilityRequested(new BSON.ObjectID(selectedListing?._id));
        }
    }, [selectedListing]);
    useEffect(() => {
        //console.log('listing selected');
        var dates = unavailableDates && unavailableDates.length ? unavailableDates : [];
        const formatted = formatAvailability(selectedListing, dates);
        const arrangedList = arrangeDates(formatted);
        setOrderedDates(arrangedList);
    }, [unavailableDates, selectedListing]);

    useEffect(() => {}, [orderedDates]);
    const minutesOffset = new Date().getTimezoneOffset();
    const utcOffset = Math.floor(minutesOffset / 60);

    useEffect(() => {
        if (searchText && searchText.length > 0) {
            if (page == 'agents') {
                searchAgentsRequested(searchText, selectedMarket.name);
            }
            if (page == 'listings') {
                searchListingsRequested(searchText, selectedMarket._id);
            }
            if (page == 'brokerages') {
                searchBrokeragesRequested(searchText, selectedMarket._id); // name -> _id
            }
        }
    }, [searchText]);
    const { register, handleSubmit, errors } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
    });

    function showAgentCard() {
        if (agents && agents.length) {
            return (
                <div>
                    {agents.map((agent: any, index: number) => {
                        function onClickAgent() {
                            console.log('agentClicked');
                            setAgentSelected(agent);
                            history.push(`allsearches/agent/${agent.markets[0].agentMlsId}`);
                        }
                        return (
                            <PersonCard
                                name={`${agent.firstName} ${agent.lastName}`}
                                firstElement={agent?.phoneNumber}
                                secondElement={agent.email}
                                thirdElement={
                                    agent.markets && agent.markets.length && agent.markets[0]
                                        ? agent.markets[0].agentMlsId
                                        : ''
                                }
                                status={agent.status}
                                type="agent"
                                onClickViewProfile={onClickAgent}
                            />
                        );
                    })}
                </div>
            );
        }
    }

    function showBrokerageCard() {
        if (brokerages && brokerages.length) {
            return (
                <div>
                    {brokerages.map((brokerage: any, index: number) => {
                        function onClickBrokerage() {
                            console.log('brokerageClicked');
                            setSelectedBrokerage(brokerage);
                            fetchBrokerageRosterRequested(brokerage._id);
                            fetchBrokerageAdminsRequested(brokerage._id);
                            history.push(`allsearches/brokerage/${brokerage.brokerageId}`);
                        }
                        return (
                            <PersonCard
                                name={brokerage.name}
                                firstElement={brokerage.brokerageId}
                                thirdElement={brokerage.agentCount}
                                status={
                                    brokerage?.adminVerificationStatus
                                        ? brokerage?.adminVerificationStatus
                                        : ''
                                }
                                hasSubscription={
                                    brokerage?.subscription?.active
                                        ? brokerage?.subscription?.active
                                        : false
                                }
                                type="brokerage"
                                onClickViewProfile={onClickBrokerage}
                            />
                        );
                    })}
                </div>
            );
        }
    }

    function showListingCard() {
        if (listings && listings.length) {
            return (
                <div>
                    {listings.map((listing: any, index: number) => {
                        return (
                            <PropertyListingCard
                                key={index}
                                cardType={'listing'}
                                onClickSchedule={() => {
                                    setSelectedListing(listing);
                                    setRenderModal(true);
                                }}
                                onClickViewMore={() => {
                                    window.alert('NOT AVALIBLE');
                                }}
                                propertyData={listing}
                                displayButtons={true}
                                onClickQueue={() => {
                                    window.alert('This wont work...like ever');
                                }}
                            />
                        );
                    })}
                </div>
            );
        }
    }
    function useOutsideClick(ref: any) {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (
                    ref.current &&
                    !ref.current.contains(event.target) &&
                    !event.target.className.includes('Dropdown-option')
                ) {
                    setRenderModal(false);
                    setSelectedListing(undefined);
                }
            }
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);

    return (
        <div className={styles.root}>
            <div className={styles.ScheduleShowingModalContainer}>
                {renderModal && orderedDates && (
                    // orderedDates.length &&

                    <ScheduleShowingModal
                        listingId={new BSON.ObjectID(selectedListing?._id)}
                        mlsId={selectedListing.listingId}
                        marketName={selectedMarket.name}
                        agentListing={selectedListing?.agentListing}
                        exitOnClick={() => {
                            setSelectedListing(null);
                            setOrderedDates([]);
                            setRenderModal(false);
                            clearShowingAvailability();
                            clearShowingScheduled();
                        }}
                        loading={loading}
                        showingCreated={scheduledShowing}
                        onClickSchedule={(startTime: Date, endTime: Date, agent: any) => {
                            scheduleShowingRequested(
                                selectedListing._id,
                                startTime,
                                endTime,
                                agent,
                                utcOffset,
                            );
                        }}
                        showingSuccessfullyCreated={showingSuccessfullyCreated}
                        clearShowingAvailability={clearShowingAvailability}
                        unavailableDates={orderedDates || []}
                        searchAgents={searchAgentsRequested}
                        searchedAgents={agents}
                    />
                )}
            </div>
            <div className={styles.header}>
                <SearchBar
                    inputStyles={
                        disabled ? styles.searchBarInputStylesDisabled : styles.searchBarInputStyles
                    }
                    onSubmit={(text: string = '') => {
                        if (text && text.length > 0) {
                            setSearchText(text);
                        }
                    }}
                    disabled={disabled}
                    placeholderText={'Search'}
                />

                <div>
                    <label className={styles.details}>Market:</label>
                    <div className={styles.formControl}>
                        <select
                            id="dropdown"
                            name="market"
                            className={styles.dropdown}
                            onChange={(e) => setSelectedMarketString(e.target.value)}

                            // ref={register({ required: 'market' })}
                        >
                            <option value="None" selected>
                                None
                            </option>
                            {markets &&
                                markets.map((market: any, index: any) => (
                                    <option key={market._id} value={JSON.stringify(market)}>
                                        {market.name}
                                    </option>
                                ))}
                        </select>
                    </div>
                </div>
            </div>
            <ButtonTabBar buttonNames={buttonOptions} setPage={setPage} currPage={page} />
            {loading && !renderModal ? (
                <div className={styles.loaderContainer}>
                    <Loader icon={Assets.Gifs.LoadingIcon2} />
                </div>
            ) : (
                <>
                    {page === 'agents' && <TableHeader type={'agent'} />}
                    {page === 'brokerages' && <TableHeader type={'brokerage'} />}
                    {
                        {
                            agents: showAgentCard(),
                            brokerages: showBrokerageCard(),
                            listings: showListingCard(),
                        }[page]
                    }
                </>
            )}
        </div>
    );
};

export default connector(AllSearches);
