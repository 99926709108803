import React from 'react';
import { BrowserRouter, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import AllSearches from './domains/dash/AllSearches';
import Calendar from './domains/dash/Calendar';
import Dashboard from './domains/dash/Dashboard';
import Sales from './domains/dash/Sales';
import Support from './domains/dash/Support';
import Marketing from './domains/dash/Marketing';
import BrokerageViewer from './domains/dash/AllSearches/BrokerageViewer';
import AgentViewer from './domains/dash/AllSearches/AgentViewer';
import Profile from './domains/dash/Profile';

import { routerRef } from './utils';
import { TrainingArticle } from './components';
import { ConnectedSideBar } from 'web-lib';
import SearchIcon from './Images/searchIcon.svg';
import HouseIcon from './Images/houseIcon.svg';
import TopHatIcon from './Images/TopHat.png';
import PhoneIcon from './Images/phone.svg';
import CalendarIcon from './Images/calendar.svg';
import FolderIcon from './Images/folder.svg';
import SearchIconSelected from './Images/searchIconSelected.svg';
import HouseIconSelected from './Images/houseIconSelected.svg';
import PhoneIconSelected from './Images/phoneSelected.svg';
import CalendarIconSelected from './Images/calendarSelected.svg';
import FolderIconSelected from './Images/folderSelected.svg';
import styles from './utils/commonStyles.module.css';
import logo from './Images/allGrey.svg';
import { connect } from 'react-redux';
import { getAdminAccount } from './domains/auth/selectors';
const history = { useHistory };

const mapStateToProps = (state: any) => ({
    account: getAdminAccount(state),
});

const connector = connect(mapStateToProps);

function AuthenticatedApp({ account }: any) {
    const Sidebar = () => {
        let listItems = [
            {
                link: '/dashboard',
                icon: HouseIcon,
                iconSelected: HouseIconSelected,
                pageTitle: 'Dashboard',
            },
            {
                link: '/allsearches',
                icon: SearchIcon,
                iconSelected: SearchIconSelected,
                pageTitle: 'Search',
            },
            {
                link: '/calendar',
                icon: CalendarIcon,
                iconSelected: CalendarIconSelected,
                pageTitle: 'Calendar',
            },
            {
                link: '/sales',
                icon: PhoneIcon,
                iconSelected: PhoneIconSelected,
                pageTitle: 'Sales',
            },
            {
                link: '/marketing',
                icon: FolderIcon,
                iconSelected: FolderIconSelected,
                pageTitle: 'Marketing',
            },
            {
                link: '/support',
                icon: FolderIcon,
                iconSelected: FolderIconSelected,
                pageTitle: 'Support',
            },
        ];

        return (
            <ConnectedSideBar
                ListItems={listItems}
                userPhotoUri={account?.profileImage ? account.profileImage : TopHatIcon}
                userProfileLink={'/profile'}
                iconStyling={styles.icons}
                iconTextStyling={styles.iconText}
                customWaterMark={logo}
                customWaterMarkStyling={styles.logo}
                loggedUser={`${account?.firstName} ${account?.lastName}`}
            />
        );
    };

    return (
        <BrowserRouter
            ref={(e) => {
                (routerRef as any).current = e;
            }}
        >
            <Switch>
                <Route
                    exact
                    path="/auth"
                    render={() => {
                        return <Redirect to="/dashboard" />;
                    }}
                />
                <Route exact path="/dashboard" render={() => <Dashboard />} />
                <Route exact path="/allsearches" render={() => <AllSearches />} />
                <Route exact path="/allsearches/brokerage/:id" render={() => <BrokerageViewer />} />
                <Route exact path="/allsearches/agent/:id" render={() => <AgentViewer />} />
                <Route exact path="/calendar" render={() => <Calendar />} />
                <Route exact path="/sales" render={() => <Sales />} />
                <Route exact path="/marketing" render={() => <Marketing />} />
                <Route exact path="/support" render={() => <Support />} />
                <Route exact path="/supportdocs/:id" render={() => <TrainingArticle />} />
                <Route exact path="/profile" render={() => <Profile />} />

                <Route exact path="*" render={() => <Dashboard />} />
            </Switch>
            <Sidebar />
        </BrowserRouter>
    );
}

export default connector(AuthenticatedApp);
