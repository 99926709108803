import { ObjectID } from 'mongodb';
import { BSON } from 'mongodb-stitch-browser-sdk';
import { STATUS } from '../../../store/api/constants';

export enum DM_ACTION {
    FetchBrokerages = '[Data Manipulation] Fetch',
    CreateBrokerage = '[Data Manipulation] Create Brokerage',
    CreateBrokerageUser = '[Data Manipulation] Create Brokerage User',
    CreateAgentUser = '[Data Manipulation] Create User',
    Select = '[Data Manipulation] Select Created',
    AddAgentContent = '[Data Manipulation] Add Agent Content',
    FetchBadges = '[Data Manipulation] Fetch Badges',
    UpdateAgent = '[Data Manipulation] Badge Interaction',
    SearchListings = '[Data Manipulation] Search Listings',
    FetchAgentClients = '[Data Manipulation] Fetch Agent Clients',
    ScheduleShowing = '[Data Manipulation] Schedule Showing',
}

export function fetchBrokeragesRequested(searchText: string) {
    return {
        type: DM_ACTION.FetchBrokerages,
        status: STATUS.Requested,
        searchText,
    } as const;
}

export function fetchBrokeragesFailed(error: Error) {
    return {
        type: DM_ACTION.FetchBrokerages,
        status: STATUS.Failed,
        error,
    } as const;
}

export const fetchBrokeragesSucceeded = (brokerages: any[]) =>
    ({
        type: DM_ACTION.FetchBrokerages,
        status: STATUS.Succeeded,
        brokerages,
    } as const);

export type FetchBrokeragesAction =
    | ReturnType<typeof fetchBrokeragesRequested>
    | ReturnType<typeof fetchBrokeragesFailed>
    | ReturnType<typeof fetchBrokeragesSucceeded>;

// -------------------------------------------------------------------------------------------------

export const createBrokerageRequested = (newBrokerage: {
    name: string;
    market: string;
    image: string;
    agentCount: number;
}) =>
    ({
        type: DM_ACTION.CreateBrokerage,
        status: STATUS.Requested,
        newBrokerage,
    } as const);

export const createBrokerageFailed = (error: Error) =>
    ({
        type: DM_ACTION.CreateBrokerage,
        status: STATUS.Failed,
        error,
    } as const);

export const createBrokerageSucceeded = (brokerage: any) =>
    ({
        type: DM_ACTION.CreateBrokerage,
        status: STATUS.Succeeded,
        brokerage,
    } as const);

export type CreateBrokerageAction =
    | ReturnType<typeof createBrokerageRequested>
    | ReturnType<typeof createBrokerageFailed>
    | ReturnType<typeof createBrokerageSucceeded>;

// -------------------------------------------------------------------------------------------------

export const addAgentContentRequested = (newContent: {
    market: string;
    type:
        | 'Blog'
        | 'News'
        | 'Lender Spotlight'
        | 'Title Splotlight'
        | 'Coaching Spotlight'
        | 'TC Spotlight'
        | 'Stats';
    title: string;
    // all the other types to later be added depending on case
}) =>
    ({
        type: DM_ACTION.AddAgentContent,
        status: STATUS.Requested,
        newContent,
    } as const);

export const addAgentContentFailed = (error: Error | null | string) =>
    ({
        type: DM_ACTION.AddAgentContent,
        status: STATUS.Failed,
        error,
    } as const);

export const addAgentContentSucceeded = () =>
    ({
        type: DM_ACTION.AddAgentContent,
        status: STATUS.Succeeded,
    } as const);

export type AddAgentContent =
    | ReturnType<typeof addAgentContentRequested>
    | ReturnType<typeof addAgentContentFailed>
    | ReturnType<typeof addAgentContentSucceeded>;

// -------------------------------------------------------------------------------------------------

export const fetchBadgesRequested = (badgeType: string | null) =>
    ({
        type: DM_ACTION.FetchBadges,
        status: STATUS.Requested,
        badgeType,
    } as const);

export const fetchBadgesFailed = (error: Error | null | string) =>
    ({
        type: DM_ACTION.FetchBadges,
        status: STATUS.Failed,
        error,
    } as const);

export const fetchBadgesSucceeded = (badges: any) =>
    ({
        type: DM_ACTION.FetchBadges,
        status: STATUS.Succeeded,
        badges,
    } as const);

export type FetchBadges =
    | ReturnType<typeof fetchBadgesRequested>
    | ReturnType<typeof fetchBadgesFailed>
    | ReturnType<typeof fetchBadgesSucceeded>;

// -------------------------------------------------------------------------------------------------

export const searchListingsRequested = (text: string | null, singleListingId: string | null) =>
    ({
        type: DM_ACTION.SearchListings,
        status: STATUS.Requested,
        text,
        singleListingId,
    } as const);

export const searchListingsFailed = (error: Error | null | string) =>
    ({
        type: DM_ACTION.SearchListings,
        status: STATUS.Failed,
        error,
    } as const);

export const searchListingsSucceeded = (listings: any) =>
    ({
        type: DM_ACTION.SearchListings,
        status: STATUS.Succeeded,
        listings,
    } as const);

export type SearchListings =
    | ReturnType<typeof searchListingsRequested>
    | ReturnType<typeof searchListingsFailed>
    | ReturnType<typeof searchListingsSucceeded>;

// -------------------------------------------------------------------------------------------------

export const fetchAgentClientsRequested = (agentId: ObjectID) =>
    ({
        type: DM_ACTION.FetchAgentClients,
        status: STATUS.Requested,
        agentId,
    } as const);

export const fetchAgentClientsFailed = (error: Error | null | string) =>
    ({
        type: DM_ACTION.FetchAgentClients,
        status: STATUS.Failed,
        error,
    } as const);

export const fetchAgentClientsSucceeded = (clients: any) =>
    ({
        type: DM_ACTION.FetchAgentClients,
        status: STATUS.Succeeded,
        clients,
    } as const);

export type FetchAgentClients =
    | ReturnType<typeof fetchAgentClientsRequested>
    | ReturnType<typeof fetchAgentClientsFailed>
    | ReturnType<typeof fetchAgentClientsSucceeded>;

// -------------------------------------------------------------------------------------------------

export const scheduleShowingRequested = (showingData: any, agent: any) =>
    ({
        type: DM_ACTION.ScheduleShowing,
        status: STATUS.Requested,
        showingData,
        agent,
    } as const);

export const scheduleShowingFailed = (error: Error | null | string) =>
    ({
        type: DM_ACTION.ScheduleShowing,
        status: STATUS.Failed,
        error,
    } as const);

export const scheduleShowingSucceeded = (clients: any) =>
    ({
        type: DM_ACTION.ScheduleShowing,
        status: STATUS.Succeeded,
        clients,
    } as const);

export type ScheduleShowing =
    | ReturnType<typeof scheduleShowingRequested>
    | ReturnType<typeof scheduleShowingFailed>
    | ReturnType<typeof scheduleShowingSucceeded>;

// -------------------------------------------------------------------------------------------------

export const updateAgentRequested = (agentId: any, updatedAgentFields: any) =>
    ({
        type: DM_ACTION.UpdateAgent,
        status: STATUS.Requested,
        agentId,
        updatedAgentFields,
    } as const);

export const updateAgentFailed = (error: Error | null | string) =>
    ({
        type: DM_ACTION.UpdateAgent,
        status: STATUS.Failed,
        error,
    } as const);

export const updateAgentSucceeded = () =>
    ({
        type: DM_ACTION.UpdateAgent,
        status: STATUS.Succeeded,
    } as const);

export type UpdateAgent =
    | ReturnType<typeof updateAgentRequested>
    | ReturnType<typeof updateAgentFailed>
    | ReturnType<typeof updateAgentSucceeded>;

// -------------------------------------------------------------------------------------------------

export const createAgentUserRequested = (newUser: {
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    marketLicenses: {
        marketId: string;
        mlsId: string;
    }[];
    brokerageId?: BSON.ObjectId;
}) =>
    ({
        type: DM_ACTION.CreateAgentUser,
        status: STATUS.Requested,
        newUser,
    } as const);

export const createBrokerageUserRequested = (newUser: {
    brokerageId: BSON.ObjectId;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
}) =>
    ({
        type: DM_ACTION.CreateBrokerageUser,
        status: STATUS.Requested,
        newUser,
    } as const);

export const createUserFailed = (
    type: DM_ACTION.CreateAgentUser | DM_ACTION.CreateBrokerageUser,
    error: Error,
) =>
    ({
        type,
        status: STATUS.Failed,
        error,
    } as const);

export const createUserSucceeded = (
    type: DM_ACTION.CreateAgentUser | DM_ACTION.CreateBrokerageUser,
    user: any,
    account: any,
) =>
    ({
        type,
        status: STATUS.Succeeded,
        user,
        account,
    } as const);

export type CreateUserAction =
    | ReturnType<typeof createAgentUserRequested>
    | ReturnType<typeof createBrokerageUserRequested>
    | ReturnType<typeof createUserFailed>
    | ReturnType<typeof createUserSucceeded>;

// -------------------------------------------------------------------------------------------------

export const selectCreatedObject = (id: BSON.ObjectId) =>
    ({
        type: DM_ACTION.Select,
        id,
    } as const);

export type DataManipulationAction =
    | FetchBrokeragesAction
    | AddAgentContent
    | FetchBadges
    | UpdateAgent
    | CreateBrokerageAction
    | CreateUserAction
    | ScheduleShowing
    | SearchListings
    | FetchAgentClients
    | ReturnType<typeof selectCreatedObject>;
