import React, { useEffect, useState } from 'react';
import { Row } from 'react-grid-system';
import { Button, Loader } from 'web-lib';
import { DateTime } from 'luxon';
import classnames from 'classnames';
import styles from './index.module.css';

import { formatPhoneNumberForDisplay, formatEmailForDisplay } from '../../utils';

import { Assets } from 'asset-lib/src/index';

interface Address {
    street: string;
    full?: string;
    city: string;
    state: string;
    postalCode: string;
}

interface PropertyData {
    listPrice: string;
    listingId: string;
    address: Address;
    beds: number;
    baths: number;
    sqft: number;
    status: string;
    photo: string;
    showType?: string;
    lockType?: string;
    lockCode?: string;
    mlsNumber?: string;
    mls?: any;
    listingStatus?: string;
    listingAgent?: string;
    phone?: string;
    photos?: string[];

    agent?: any;
    office?: any;
    property: any;
    agentListing?: any;
}

type PropertyInfoCardProps = {
    onClickSchedule?: Function;
    propertyData: PropertyData;
};

enum ShowingLabels {
    showType = 'Showing Type: ',
    lockType = 'Lock Box Type: ',
    lockCode = 'Lock Box Code: ',
}

enum ListingLabels {
    mlsNumber = 'MLS#: ',
    status = 'Listing Status: ',
    agent = 'Listing Agent: ',
    phone = 'Phone: ',
    office = 'Office: ',
}

const ListingCard = ({
    onClickSchedule = () => {},

    propertyData,
}: PropertyInfoCardProps) => {
    const { address } = propertyData;
    const listingIsNotConnected = propertyData.agentListing === undefined;
    const [isBroken, setIsBroken] = useState<boolean>(false);

    const DetailsLabel = ({ label, value }: { label?: string; value?: string }) => {
        return (
            <Row>
                <div className={styles.labelconstant}>{label}&nbsp;</div>
                <div className={styles.labeltext}>{value}</div>
            </Row>
        );
    };

    const showingCard = (
        <div className={styles.showingDetailsContainer}>
            <DetailsLabel
                label={ShowingLabels.showType}
                value={propertyData?.agentListing?.type || '--'}
            />
            <DetailsLabel
                label={ShowingLabels.lockType}
                value={propertyData?.agentListing?.lockboxType || '--'}
            />
            <DetailsLabel
                label={ShowingLabels.lockCode}
                value={propertyData?.agentListing?.lockCombo || '--'}
            />
        </div>
    );

    const unconnectedCard = (
        <div className={styles.myListingContainerUnconnected}>
            <h5 className={styles.unconnectedMessageTitle}>Please Connect Your Listing</h5>
            This listing is not connected. Connect this listing to manage incoming showing requests,
            view feedback, and more.
        </div>
    );

    const formattedAddress =
        address.full && address.full.length > 30
            ? address.full.substring(0, 30) + '...'
            : address.full;

    const formattedPropertyStats = () => {
        let numBaths: number = 0;
        let formattedPriceBackwards: string = '';
        let formattedPriceForwards: string = '';
        let iteration = 0;
        let priceLength = propertyData.listPrice.toString().length;
        for (let i = priceLength; i !== -1; i--) {
            formattedPriceBackwards += propertyData.listPrice.toString().charAt(i);
            if (
                iteration % 3 === 0 &&
                iteration !== 0 &&
                iteration !== propertyData.listPrice.toString().length
            ) {
                formattedPriceBackwards += ',';
            }
            iteration++;
        }
        formattedPriceForwards = formattedPriceBackwards.split('').reverse().join('');
        if (propertyData.property.bathsHalf > 0) {
            numBaths += 0.5 * propertyData.property.bathsHalf;
        }
        if (propertyData.property.bathsFull > 0) {
            numBaths += propertyData.property.bathsFull;
        }
        return {
            baths: numBaths || '--',
            beds: propertyData.property.bedrooms || '--',
            price: formattedPriceForwards || '--',
        };
    };

    const formattedCityStateAddress =
        propertyData.address.city +
        ', ' +
        propertyData.address.state +
        ' ' +
        propertyData.address.postalCode;

    return (
        <div className={styles.root}>
            {/* photo */}
            <div className={styles.imageContainer}>
                <div className={styles.placeholder}>
                    {propertyData.photos ? (
                        <img
                            className={styles.image}
                            src={propertyData?.photos[0]}
                            alt={'property'}
                            onError={() => setIsBroken(true)}
                        />
                    ) : (
                        <img className={styles.img} />
                    )}
                </div>
                <Button
                    buttonText={'View More'}
                    className={styles.viewMoreImageButton}
                    onClick={() => console.log('clickmore')}
                    disabled={false}
                />
            </div>

            <div className={styles.listingDetailsColumn}>
                <div className={styles.listingAddressRow}>
                    {formattedAddress}
                    {listingIsNotConnected && (
                        <div className={styles.listingNotConnectedContainer}>
                            <img
                                className={styles.notConnectedIcon}
                                src={Assets.Svgs.UnconnectedIcon}
                                alt={'icon'}
                            />
                        </div>
                    )}
                </div>
                <div className={styles.listingCityStateZip}>{formattedCityStateAddress}</div>
                <div className={styles.listingStatsIconText}>
                    <img className={styles.listingStatsIcon} src={Assets.Svgs.MintDollarSign} />
                    {formattedPropertyStats().price}
                </div>
                <div className={styles.listingStatsIconText}>
                    <img className={styles.listingStatsIcon} src={Assets.Svgs.BedIcon} />
                    {formattedPropertyStats().beds} bedrooms
                </div>
                <div className={styles.listingStatsIconText}>
                    <img className={styles.listingStatsIcon} src={Assets.Svgs.BathIcon} />
                    {formattedPropertyStats().baths} bathrooms
                </div>
            </div>
            <div className={styles.listingDetailsContainerOther}>
                <DetailsLabel
                    label={ListingLabels.mlsNumber}
                    value={propertyData.listingId || '--'}
                />
                <DetailsLabel
                    label={ListingLabels.status}
                    value={propertyData?.mls?.status || '--'}
                />
                <DetailsLabel
                    label={ListingLabels.agent}
                    value={
                        propertyData.agent
                            ? propertyData?.agent?.firstName + ' ' + propertyData?.agent?.lastName
                            : '--'
                    }
                />
                <DetailsLabel
                    label={ListingLabels.phone}
                    value={
                        formatPhoneNumberForDisplay(propertyData.agent?.contact?.cell) ||
                        formatPhoneNumberForDisplay(propertyData.agent?.contact?.office) ||
                        '--'
                    }
                />
                <DetailsLabel
                    label={ListingLabels.office}
                    value={propertyData?.office?.name || '--'}
                />
            </div>

            <div className={styles.scheduleQueueButtonsContainer}>
                <Button
                    buttonText={'Schedule'}
                    className={styles.scheduleButton}
                    onClick={() => onClickSchedule()}
                    disabled={false}
                />
            </div>
        </div>
    );
};

export default ListingCard;
