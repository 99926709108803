import React from 'react';
import styles from '../index.module.css';
import { Container, Row, Col } from 'react-grid-system';
import { chartSources } from '../mongoCharts';

//let debug = true;
function MarketingDash() {
    return (
        <div className={styles.root2}>
            <div className={styles.title}>Marketing Dashboard</div>
            <Row justify="center" className={styles.chart1} gutterWidth={2}>
                <Col xs="content" className={styles.colChart}>
                    <iframe
                        title="chart"
                        className={styles.iframeStyleQuarter}
                        src={chartSources.totalAgents}
                    ></iframe>
                </Col>
                <Col xs="content" className={styles.colChart}>
                    <iframe
                        title="chart"
                        className={styles.iframeStyleQuarter}
                        src={chartSources.subscriptions}
                    ></iframe>
                </Col>
                <Col xs="content" className={styles.colChart}>
                    <iframe
                        title="chart"
                        className={styles.iframeStyleQuarter}
                        src={chartSources.connectedListings}
                    ></iframe>
                </Col>
                <Col xs="content" className={styles.colChart}>
                    <iframe
                        title="chart"
                        className={styles.iframeStyleQuarter}
                        src={chartSources.totalConsumer}
                    ></iframe>
                </Col>

                <Col xs="content" className={styles.colChart}>
                    <iframe
                        title="chart"
                        className={styles.iframeStyleHalf}
                        src={chartSources.showingsForDay}
                    ></iframe>
                </Col>
                <Col xs="content" className={styles.colChart}>
                    <iframe
                        title="chart"
                        className={styles.iframeStyleHalf}
                        src={chartSources.completedShowingsMonth}
                    ></iframe>
                </Col>
            </Row>

            <Row justify="center" className={styles.chart2} gutterWidth={2}>
                <Col xs="content" className={styles.colChart}>
                    <iframe
                        title="chart"
                        className={styles.iframeStyleFull}
                        src={chartSources.agentsSubsMarket}
                    ></iframe>
                </Col>
                <Col xs="content" className={styles.colChart}>
                    <iframe
                        title="chart"
                        className={styles.iframeStyleFull}
                        src={chartSources.connectedListingsMarket}
                    ></iframe>
                </Col>
                <Col xs="content" className={styles.colChart}>
                    <iframe
                        title="chart"
                        className={styles.iframeStyleFull}
                        src={chartSources.delegationsLeadsMarket}
                    ></iframe>
                </Col>
                <Col xs="content" className={styles.colChart}>
                    <iframe
                        title="chart"
                        className={styles.iframeStyleFull}
                        src={chartSources.showingRequestStatus}
                    ></iframe>
                </Col>
            </Row>

            <Row justify="center" className={styles.chart1} gutterWidth={2}>
                <Col xs="content" className={styles.colChart}>
                    <iframe
                        title="chart"
                        className={styles.iframeStyleDouble}
                        src={chartSources.agentsEachDay}
                    ></iframe>
                </Col>

                <Col xs="content" className={styles.colChart}>
                    <iframe
                        title="chart"
                        className={styles.iframeStyleDouble}
                        src={chartSources.consumersEachDay}
                    ></iframe>
                </Col>
            </Row>

            <Row justify="center" className={styles.chart2} gutterWidth={2}>
                <Col xs="content" className={styles.colChart}>
                    <iframe
                        title="chart"
                        className={styles.iframeStyleDouble}
                        src={chartSources.agentsOverTime}
                    ></iframe>
                </Col>

                <Col xs="content" className={styles.colChart}>
                    <iframe
                        title="chart"
                        className={styles.iframeStyleDouble}
                        src={chartSources.consumersOverTime}
                    ></iframe>
                </Col>
            </Row>

            <Row justify="center" className={styles.chart1} gutterWidth={2}>
                <Col xs="content" className={styles.colChart}>
                    <iframe
                        title="chart"
                        className={styles.iframeStyleDouble}
                        src={chartSources.requestedShowingsMap}
                    ></iframe>
                </Col>

                <Col xs="content" className={styles.colChart}>
                    <iframe
                        title="chart"
                        className={styles.iframeStyleDouble}
                        src={chartSources.confirmedShowingsMap}
                    ></iframe>
                </Col>
            </Row>

            <Row justify="center" className={styles.chart1} gutterWidth={2}>
                <Col xs="content" className={styles.colChart}>
                    <iframe
                        title="chart"
                        className={styles.iframeStyleDouble}
                        src={chartSources.completedShowingsMap}
                    ></iframe>
                </Col>

                <Col xs="content" className={styles.colChart}>
                    <iframe
                        title="chart"
                        className={styles.iframeStyleDouble}
                        src={chartSources.cancelledShowingsMap}
                    ></iframe>
                </Col>
            </Row>

            <Row justify="center" className={styles.chart1} gutterWidth={2}>
                <Col xs="content" className={styles.colChart}>
                    <iframe
                        title="chart"
                        className={styles.iframeStyleDouble}
                        src={chartSources.deniedShowingsMap}
                    ></iframe>
                </Col>
            </Row>
        </div>
    );
}

export default MarketingDash;
