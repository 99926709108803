import React, { useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useHistory } from 'react-router-dom';
import styles from './index.module.css';
import { Button, ButtonTabBar } from '../../../components';
import AdminInfo from './AdminInfo';
import TopHat from '../../../Images/TopHat.png';
import { connect } from 'react-redux';
import { getAdminAccount } from '../../auth/selectors';
import { formatPhoneNumberForDisplay } from '../../../utils';

const mapStateToProps = (state: any) => ({
    account: getAdminAccount(state),
});

const connector = connect(mapStateToProps);

const Profile = ({account}: any) => {
    return (
        <div className={styles.root}>
            <h1>Admin Boi</h1>
            <AdminInfo 
            profileImage={account.profileImage ? account.profileImage : TopHat}
            firstName={account.firstName}
            lastName={account.lastName}
            department={account.department}
            phoneNumber={formatPhoneNumberForDisplay(account.phoneNumber)}
            email={account.email}
            devAgentId={account.agentMlsIds.devAgentId}
            stagingAgentId={account.agentMlsIds.stagingAgentId}
            prodAgentId={account.agentMlsIds.prodAgentId}
            />
        </div>
    );
};

export default connector(Profile);
