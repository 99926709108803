import React, { useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useHistory } from 'react-router-dom';
import styles from './index.module.css';
import { Button, ButtonTabBar } from '../../../components';
import GeneralDash from '../../../components/MongoChartDashboards/GeneralDashboard';
import LaunchDash from '../../../components/MongoChartDashboards/LaunchDashboard/index';

const DashBoard = () => {
    let history = useHistory();
    function handleClick(path: string) {
        console.log('path', path);
        history.push(path);
    }
    const buttonOptions: { name: string; page: string }[] = [
        { name: 'General Dashboard', page: 'genDash' },
        { name: 'Launch Dashboard', page: 'launchDash' },
    ];
    const [page, setPage] = useState<string>('genDash');

    return (
        <div className={styles.root}>
            <ButtonTabBar buttonNames={buttonOptions} setPage={setPage} currPage={page} />
            {
                {
                    genDash: <GeneralDash />,
                    launchDash: <LaunchDash />,
                }[page]
            }
        </div>
    );
};

export default DashBoard;
